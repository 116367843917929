<template>
  <div class="flex flex-col justify-between bg-gray-200 dark:bg-mhGray-700 border border-gray-700 rounded-xl
   transition duration-300 hover:shadow-2xl p-6">
    <div class="space-y-2 mb-4">
      <div class="flex justify-between items-center">
        <h6 class="text-xl font-extrabold text-green-500">{{ plan.title }}</h6>
        <p class="text-xl font-extrabold">{{ plan.hash_power }}</p>
      </div>

      <div class="flex justify-between items-center">
        <p class="font-light dark:text-gray-400">{{ plan.subtitle }}</p>
        <p class="font-light dark:text-gray-400">TH/s</p>
      </div>
    </div>

    <!--    <hr class="-mx-6 border-gray-700">-->
    <RatingStars :rating="4"
                 class="" />

    <div class="flex flex-col text-sm space-y-4 my-6">
      <!-- Plan duration -->
      <div>
        <p class="font-semibold dark:text-gray-200">
          <span>{{ plan.duration }}</span>
          <span>{{ " " + $t("day") }}</span>
        </p>
        <p class="font-light dark:text-gray-400">{{ $t("mineBitcoin") }}</p>
      </div>

      <!-- Mine algorithm -->
      <div>
        <p class="font-semibold dark:text-gray-200">{{ plan.algorithm }}</p>

        <p class="font-light dark:text-gray-400">{{ " " + $t("mineAlgorithm") }}</p>
      </div>

      <!-- Maintenance fee -->
      <div>
        <p class="font-semibold text-green-500">
          <span>{{ plan.maintenance_fee }}</span>
          <span>{{ " " + $t("toman") }}</span>
        </p>
        <p class="font-light dark:text-gray-400">{{ $t("maintenanceFee") }}</p>
      </div>

      <!-- Hash power -->
      <div>
        <p class="font-semibold dark:text-gray-200">
          <span>{{ plan.hash_power }}</span>
          <span>{{ "TH/s" }}</span>
        </p>
        <p class="font-light dark:text-gray-400">{{ $t("minePower") }}</p>
      </div>
    </div>

    <div class="flex flex-col justify-center text-end">
      <!-- Dollar price -->
      <div class="flex justify-end items-center gap-1">
        <span class="text-lg font-bold">{{ formatNumber(plan.price) }}</span>
        <span class="dark:text-gray-400">$</span>
      </div>

      <!-- Rial price -->
      <div class="flex justify-end items-center gap-1">
        <span class="text-lg font-bold">{{ formatNumber(tomanPrice(plan.price)) }}</span>
        <span class="dark:text-gray-400">{{ $t("toman") }}</span>
      </div>
    </div>

    <base-button :loading="addingToCart"
                 class="btn-lg text-sm rounded-xl mt-4"
                 type="button"
                 variant="primary"
                 @click="addToCart(plan.id)">
      {{ $t("pages.home.packages.startNow") }}
    </base-button>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import Notifier from "@/core/utils/NotifierProvider";
import useNumberFormat from "@/core/composables/useNumberFormat";
import useCurrencyPrices from "@/core/composables/useCurrencyPrices";
import RatingStars from "@/components/utils/form/RatingStars";
import store from "@/store";
import { ADD_TO_CART } from "@/store/enums/actions";

export default {
  name: "PricingPlan",
  components: {
    RatingStars,
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n({
      messages: {
        fa: {
          successMessage: "طرح مورد نظر به سبد خرید اضافه شد.",
        },
        en: {
          successMessage: "Successfully added to the cart.",
        },
      },
    });

    const { formatNumber } = useNumberFormat();
    const { USDT_SELL } = useCurrencyPrices();

    return {
      t,
      formatNumber,
      USDT_SELL,
    };
  },
  data() {
    return {
      addingToCart: false,
    };
  },
  methods: {
    tomanPrice(usdPrice) {
      return usdPrice * this.USDT_SELL;
    },
    addToCart(planId) {
      this.addingToCart = true;

      const newPlan = {
        plan_id: planId,
        refresh: 0,
      };

      store.dispatch(ADD_TO_CART, newPlan)
        .then(() => {
          Notifier.success(this.t("successMessage"));
          this.$router.push({ name: "Cart" });
        })
        .finally(() => {
          this.addingToCart = false;
        });
    },
  },
};
</script>
