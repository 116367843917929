<template>
  <TabGroup>
    <TabList class="flex justify-center overflow-x-auto gap-4 px-1 pt-1 pb-3">
      <Tab v-for="(tab, tabIdx) in Object.keys(tabs)"
           :key="tabIdx"
           v-slot="{ selected }"
           as="template">
        <button :class="['min-w-max font-medium px-1 py-2',
                         'focus:outline-none focus-visible:ring-2 ring-gray-400 ring-opacity-60',
                         selected
                           ? 'border-b-2 border-b-green-400'
                           : 'border-b-0 opacity-80',
                         ]">
          {{ tab }}
        </button>
      </Tab>
    </TabList>

    <TabPanels class="mt-4">
      <TabPanel v-for="(panel, panelIdx) in Object.values(tabs)"
                :key="panelIdx"
                :unmount="!keepAlive"
                class="focus:outline-none">
        <component :is="panel" />
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";

export default {
  name: "BaseTabs",
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
  },
  props: {
    tabs: {
      type: Object,
      required: true,
    },
    keepAlive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
