import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";

import { LOGIN, LOGOUT, REGISTER, UPDATE_USER, VERIFY_AUTH } from "@/store/enums/actions";
import {
  CHANGE_COMPONENT,
  CHANGE_STEP,
  PURGE_AUTH,
  PURGE_BALANCE,
  SET_AUTH,
  SET_BALANCE,
  SET_IDENTIFICATIONS,
  SET_ISAPPROVEDEMAIL,
} from "@/store/enums/mutations";

const state = {
  user: {},
  balance: {},
  identifications: {},
  isApprovedEmailState: false,
  stepVerification: 0,
  currentComponent: "SaveMobile",
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  userBalance(state) {
    return state.balance;
  },
  userStep(state) {
    return state.stepVerification;
  },
  userIdentifications(state) {
    return state.identifications;
  },
  userComponentVerification(state) {
    return state.currentComponent;
  },
  isApprovedEmail(state) {
    return state.isApprovedEmailState;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isTwoFaActive() {
    const twoFa = state.user?.["two_factor"];

    if (!twoFa?.length > 0)
      return false;

    return !!+state.user?.["two_factor"] || false;
  },
};

const actions = {
  [LOGIN]({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          if (data.data["2fa"]) {
            reject(data);
            return;
          }

          commit(SET_AUTH, data.data);
          JwtService.saveToken(data.data.token);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [LOGOUT]({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.post("logout")
        .then(() => {
          commit(PURGE_AUTH);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [REGISTER]({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("register", credentials)
        .then(({ data }) => {
          commit(SET_AUTH, data.data);
          JwtService.saveToken(data.data.token);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [VERIFY_AUTH]({ commit }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise((resolve, reject) => {
        ApiService.get("account/user-info")
          .then(({ data }) => {
            commit(SET_AUTH, data.data);
            commit(SET_BALANCE, data.data);
            commit(SET_ISAPPROVEDEMAIL, data.data);
            commit(SET_IDENTIFICATIONS, data.data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      commit(PURGE_AUTH);
      commit(PURGE_BALANCE);
    }
  },
  [UPDATE_USER]({ commit }, payload) {
    const { email, username, password, image, bio } = payload;
    const user = {
      email,
      username,
      bio,
      image,
    };
    if (password)
      user.password = password;

    return ApiService.put("user", user).then(({ data }) => {
      commit(SET_AUTH, data.user);
      return data;
    });
  },
};

const mutations = {
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user = data.user;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    JwtService.destroyToken();
  },
  [SET_BALANCE](state, data) {
    state.balance = data.balance;
  },
  [SET_ISAPPROVEDEMAIL](state, data) {
    const { identifications } = data;

    if (identifications == null) {
      state.isApprovedEmailState = false;
      return;
    }
    if (identifications[0].approved_email == 1) {
      state.isApprovedEmailState = true;
    }
  },
  [SET_IDENTIFICATIONS](state, data) {
    if (data.identifications && data.identifications.length > 0)
      state.identifications = data.identifications[0];
  },
  [CHANGE_STEP](state, number) {
    state.stepVerification = number;
  },
  [CHANGE_COMPONENT](state, component) {
    state.currentComponent = component;
  },
  [PURGE_BALANCE](state) {
    state.balance = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
