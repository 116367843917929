import NProgress from "nprogress";

NProgress.configure({
  showSpinner: false,
  rtl: true,
});

const Progress = {
  start() {
    NProgress.start();
  },
  set(value) {
    NProgress.set(value)
  },
  done() {
    NProgress.done()
  },
}

export default Progress;