<template>
  <router-view class="transition-colors duration-500" />

  <SWUpdatePrompt />
</template>

<script>
import { nextTick } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import SWUpdatePrompt from "@/components/utils/alerts/SWUpdatePrompt";

const DEFAULT_TITLE = "ماخ‌ماینینگ";

export default {
  name: "App",
  components: {
    SWUpdatePrompt,
  },
  setup() {
    const { afterEach } = useRouter();

    const { t } = useI18n();

    afterEach((to) => {
      nextTick(() => {
        document.title =
          to.meta && to.meta.title
            ? t(to.meta.title) + " - " + t("makhhash")
            : DEFAULT_TITLE;
      });

      return true;
    });
  },
};
</script>
