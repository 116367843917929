<template>
  <div class="relative group rounded-3xl cursor-pointer transition duration-300 hover:shadow-xl">
    <img :src="image"
         alt="img"
         class="filter brightness-50 saturate-50 rounded-3xl w-full h-[300px]">

    <PlayIcon class="h-12 w-12 absolute top-[40%] right-[43%] group-hover:scale-125 transition duration-300 fill-gray-100" />
  </div>
</template>

<script>
import { PlayIcon } from "@heroicons/vue/solid";

export default {
  name: "VideoComment",
  components: {
    PlayIcon,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
  },
};
</script>
