import ApiService from "@/core/services/ApiService";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import Notifier from "@/core/utils/NotifierProvider";
import { PURGE_AUTH } from "@/store/enums/mutations";
import JwtService from "@/core/services/JwtService";

import { ADD_TO_CART, GET_CART_ITEMS } from "@/store/enums/actions";
import {
  ADD_CART_ITEM,
  SET_CART_ITEMS,
  SET_CART_ITEMS_COUNT,
} from "@/store/enums/mutations";

const state = {
  items: [],
  count: 0,
};

const getters = {
  cartItems(state) {
    return state.items;
  },
  cartItemsCount(state) {
    return state.count;
  },
};

const actions = {
  [GET_CART_ITEMS]({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("carts?page=1&limit=5&sortBy[created_at]=DESC")
        .then(({ data }) => {
          const { data: items, total } = data.data;
          commit(SET_CART_ITEMS, items);
          commit(SET_CART_ITEMS_COUNT, total);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ADD_TO_CART]({ commit, state }, plan) {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_API_V1_BASE_URL,
    });
    instance.interceptors.response.use(
      function (response) {
        if (!response.data.error) {
          return response;
        }

        Notifier.error(response.data.message);

        return Promise.reject(response);
      },
      function (error) {
        const status = error.response?.status || 500;
        const data = error.response?.data || "";

        // we can handle global errors here
        switch (status) {
          // authentication (token related issues)
          case 401: {
            store.commit(PURGE_AUTH);

            router.push({ name: "Login" });

            return Promise.reject(data);
          }

          // unprocessable
          case 422: {
            const errors = Object.values(data.errors);

            errors.forEach((errorItem) => {
              errorItem.forEach((errorMessage) => {
                Notifier.error(errorMessage);
              });
            });

            return Promise.reject(data);
          }

          // Too many requests
          case 429: {
            Notifier.error(
              "تعداد درخواست بیش از حد مجاز بوده است! لطفا دقایقی دیگر دوباره امتحان کنید."
            );
            return Promise.reject(error);
          }

          // generic api error (server related) unexpected
          default: {
            Notifier.error("خطایی رخ داده است. لطفا مجددا تلاش کنید.");
            router.push({ name: "Error" });
            return Promise.reject(error);
          }
        }
      }
    );
    instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    return new Promise((resolve, reject) => {
      instance
        .post("add-to-cart", plan)
        .then(({ data }) => {
          const { cart: item } = data.data;
          commit(ADD_CART_ITEM, item);
          commit(SET_CART_ITEMS_COUNT, state.count + 1);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  [SET_CART_ITEMS](state, items) {
    state.items = items;
  },
  [ADD_CART_ITEM](state, item) {
    state.items.pop();
    state.items.unshift(item);
  },
  [SET_CART_ITEMS_COUNT](state, count) {
    state.count = count;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
