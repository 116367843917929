<template>
  <a
    v-if="isExternal"
    :class="['btn', buttonClass]"
    target="_blank"
    rel="noopener"
    :href="to"
  >
    <i v-if="icon"
       class="align-middle text-sm me-3"
       :class="icon"></i>
    <slot />
  </a>

  <router-link
    v-else
    :class="['btn', buttonClass]"
    v-bind="$props"
  >
    <i v-if="icon"
       class="align-middle text-sm me-3"
       :class="icon"></i>
    <slot />
  </router-link>
</template>

<script>
import { RouterLink } from "vue-router";
import ButtonVariantMixin from "@/core/mixins/ButtonVariantMixin";

export default {
  name: "BaseLink",
  mixins: [ButtonVariantMixin],
  props: {
    ...RouterLink.props,
  },
  computed: {
    isExternal() {
      return typeof this.to === "string" && this.to.startsWith("http");
    },
  },
};
</script>
