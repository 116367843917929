// Authentication module
export const SET_AUTH = "setUser";
export const PURGE_AUTH = "logOut";
export const SET_BALANCE = "setBalance";
export const PURGE_BALANCE = "clearBalance";
export const SET_IDENTIFICATIONS = "setIdentifications";
export const CHANGE_STEP = "changeStep";
export const CHANGE_COMPONENT = "changeComponent";
export const SET_ISAPPROVEDEMAIL = "setIsApprovedEmail";

// Locale Module
export const SET_LOCALE = "setLocale";

// Cart Module
export const SET_CART_ITEMS = "setCartItems";
export const ADD_CART_ITEM = "addCartItem";
export const SET_CART_ITEMS_COUNT = "setCartItemsCount";
