import { createStore } from "vuex";
import Auth from "@/store/modules/AuthModule";
import Locale from "@/store/modules/LocaleModule";
import Cart from "@/store/modules/CartModule";

export default createStore({
  modules: {
    Auth,
    Locale,
    Cart,
  },
});
