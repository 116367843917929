import { configure, defineRule } from "vee-validate";
import {
  confirmed,
  digits,
  email,
  ext,
  is,
  image,
  length,
  max,
  max_value,
  min,
  min_value,
  numeric,
  regex,
  required,
  size,
} from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";

import en from "@vee-validate/i18n/dist/locale/en.json";
import fa from "@vee-validate/i18n/dist/locale/fa.json";
import ar from "@vee-validate/i18n/dist/locale/ar.json";

export function initVeeValidate() {
  defineRule("required", required);
  defineRule("email", email);
  defineRule("min", min);
  defineRule("max", max);
  defineRule("regex", regex);
  defineRule("digits", digits);
  defineRule("numeric", numeric);
  defineRule("ext", ext);
  defineRule("size", size);
  defineRule("min_value", min_value);
  defineRule("max_value", max_value);
  defineRule("length", length);
  defineRule("is", is);
  defineRule("confirmed", confirmed);
  defineRule("image", image);

  // Updating default vee-validate configuration
  configure({
    generateMessage: localize({
      en,
      fa: {
        messages: fa.messages,
        names: {
          name: "نام",
          last_name: "نام خانوادگی",
          mobile: "تلفن همراه",
          family: "نام خانوادگی",
          email: "ایمیل",
          password: "رمز عبور",
          current_password: "رمز عبور فعلی",
          confirmation: "تأیید رمز عبور",
          password_confirmation: "تأیید رمز عبور",
          referral: "کد معرف",
          uuid: "کد ملی",
          idCard: "کارت ملی",
          zip_code: "کد پستی",
          address: "آدرس",
          phone: "شماره تلفن",
          father_name: "نام پدر",
          title: "عنوان",
          subtitle: "عنوان پلن",
          hash_power: "قدرت استخراج",
          duration: "مدت زمان استخراج",
          algorithm: "الگوریتم استخراج",
          maintain_fee: "هزینه نگهداری",
          description: "توضیحات",
          start_date: "تاریخ شروع",
          user_id: "کاربر",
          subject: "عنوان",
          message: "پیام",
          //
          walletName: "نام کیف پول",
          cardNumber: "شماره کارت",
          sheba: "شماره شبا",
          walletAddress: "آدرس کیف پول",
          //
          withdrawAmount: "مبلغ درخواست",
          // general
          amount: "مبلغ",
          accept: "پذیرفتن موافقتنامه",
          // 2FA
          twoFACode: "کد تأیید دو مرحله‌ای",
          verifyCode: "کد تأیید",
          // deposit
          txId: "آیدی تراکنش",
          receiptNumber: "شناسه فیش",
          // ticket
          reply: "پاسخ",
          attachment: "پیوست",
        },
      },
      ar,
    }),
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: true,
  });

  setLocale("fa");
}
