<template>
  <div v-show="show"
       :class="[overlayType]"
       class="inset-0 w-full h-full bg-black bg-opacity-70 z-40">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Overlay",
  props: {
    type: {
      type: String,
      default: "fixed",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    overlayType() {
      return this.type === "absolute" ? "absolute" : "fixed";
    },
  },
};
</script>
