<template>
  <button :class="['btn', buttonClass]"
          :disabled="loading">

    <i v-if="icon"
       v-show="!loading"
       :class="icon"
       class="align-middle text-sm me-3"></i>

    <Spinner v-show="loading"
             class="me-3 !h-6 !w-6"
             light />

    <slot />

  </button>
</template>

<script>
import ButtonVariantMixin from "@/core/mixins/ButtonVariantMixin";

export default {
  name: "BaseButton",
  mixins: [ButtonVariantMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
