import { CHANGE_LOCALE } from "@/store/enums/actions";
import { SET_LOCALE } from "@/store/enums/mutations";

const state = {
  locale: "",
};

const getters = {
  currentLocale(state) {
    return state.locale;
  },
};

const actions = {
  [CHANGE_LOCALE]({ commit }, locale) {
    return new Promise((resolve) => {
      commit(SET_LOCALE, locale);

      resolve(locale);
    });
  },
};

const mutations = {
  [SET_LOCALE](state, locale) {
    state.locale = locale;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
