<template>
  <label :for="id"
         class="block text-sm dark:text-gray-300 mb-2">
    <slot />
  </label>
</template>

<script>
export default {
  name: "BaseLabel",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>
