<template>
  <div class="overflow-x-auto px-2">
    <div class="align-middle inline-block min-w-full">
      <div
        class="overflow-hidden sm:rounded-lg"
      >
        <table class="table w-full border-separate text-sm">
          <thead>
            <th
              scope="col"
              class="font-normal text-start px-6 py-3"
              v-for="(column, index) in columns"
              :key="index"
            >
              {{ column.label }}
            </th>
            <th class="font-normal text-start px-4" v-if="actions.length">
              عملیات
            </th>
          </thead>
          <tbody class="space-y-2">
            <tr v-if="!items.length" class="bg-mhGray-800 hover:bg-opacity-50 transition-colors text-center">
              <td
                class="whitespace-nowrap font-light text-gray-300 border-t border-b border-gray-700 first:border-s first:rounded-s-xl last:border-e last:rounded-e-xl px-4 py-6"
                :colspan="columns.length">هیچ اطلاعاتی برای نمایش وجود ندارد!
              </td>
            </tr>
            <tr
              v-else
              class="bg-mhGray-800 hover:bg-opacity-50 transition-colors"
              v-for="(item, index) in items"
              :key="index"
            >
              <td
                class="whitespace-nowrap font-light text-gray-300 border-t border-b border-gray-700 first:border-s first:rounded-s-xl last:border-e last:rounded-e-xl px-4 py-6"
                v-for="(column, indexColumn) in columns"
                :key="indexColumn"
              >
                {{ item[column.field] }}
              </td>
              <!--              <td-->
              <!--                class="whitespace-nowrap font-light text-gray-300 border-t border-b border-gray-700 first:border-s first:rounded-s-xl last:border-e last:rounded-e-xl px-4 py-6"-->
              <!--                v-if="actions.length"-->
              <!--              >-->
              <!--                <div class="flex items-center gap-1">-->
              <!--                  <div-->
              <!--                    v-for="(action, actionIndex) in actions"-->
              <!--                    :key="actionIndex"-->
              <!--                  >-->
              <!--                    <base-link-->
              <!--                      v-if="hasAction(item, action)"-->
              <!--                      class="btn-sm"-->
              <!--                      :to="action.details.link"-->
              <!--                      :variant="action.details.variant"-->
              <!--                    >{{ action.title }}-->
              <!--                    </base-link>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </td>-->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// const sampleColumns = [
//   { label: "شماره درخواست", field: "track_code" },
//   { label: "مقدار", field: "amount" },
//   { label: "کیف پول مقصد", field: "wallet_title" },
//   { label: "وضعیت", field: "status" },
//   { label: "زمان", field: "created_date" }
// ];
// const sampleData = {
//   items: [
//     {
//       data: {
//         track_code: "1",
//         amount: "3000000 ریال",
//         wallet_title: "بانک ملی تستی",
//         status: 1,
//         created_date: "1400/08/12"
//       }
//     },
//     {
//       data: {
//         track_code: "2",
//         amount: "4280000 ریال",
//         wallet_title: "بانک ملی تستی",
//         status: 2,
//         created_date: "1400/01/29"
//       }
//     },
//     {
//       data: {
//         track_code: "3",
//         amount: "3670000 ریال",
//         wallet_title: "بانک ملی تستی",
//         status: 3,
//         created_date: "1399/11/12"
//       },
//       options: {
//         actions: [
//           {
//             key: "cancel",
//             show: false
//           }
//         ]
//       }
//     }
//   ],
//   actions: [
//     {
//       key: "cancel",
//       title: "لغو",
//       details: {
//         type: "button",
//         variant: "danger",
//         link: "#"
//       }
//     }
//   ]
// }

export default {
  name: "ReportTable",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    actions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    sort() {},
    sortNumbers() {},
    sortString() {},
    hasAction(item, action) {
      const itemActionOptions = item.options?.actions?.find(
        a => a.key == action.key,
      );

      if (!itemActionOptions) return true;

      return itemActionOptions.show;
    },
  },
};
</script>

<style scoped>
table {
  border-spacing: 0 10px;
}
</style>
