<template>
  <div class="flex flex-col items-center text-center transition duration-300 rounded-xl hover:shadow-2xl
              border border-transparent hover:bg-gray-200 dark:hover:bg-mhGray-700 hover:border hover:border-gray-700
              p-6 space-y-6">
    <img class="w-auto h-48"
         :src="require(`@/assets/images/pages/${image}`)"
         alt="makhhash feature" />

    <h6 class="font-black text-mhGray-900 dark:text-gray-200">{{ title }}</h6>

    <p class="font-light text-mhGray-900 dark:text-gray-300">{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
