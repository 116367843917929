// Authentication module
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const VERIFY_AUTH = "checkAuth";
export const GET_IDENTIFICATION = "getIdentification";

// Locale Module
export const CHANGE_LOCALE = "changeLocale";

// Cart Module
export const GET_CART_ITEMS = "getCartItems";
export const ADD_TO_CART = "addToCart";
