<template>
  <div class="relative">
    <div class="bg-gradient-to-b from-green-900 to-green-500 rounded-xl px-10 py-6">
      <div class="flex items-center">
        <div class="h-1.5 w-1.5 bg-green-400 rounded-full"></div>

        <h4 class="text-xl font-extrabold text-gray-100 ps-2">{{ $t("pages.home.packages.customPlanTitle") }}</h4>
      </div>

      <p class="font-light text-gray-300 mt-4 mb-8">
        {{ $t("pages.home.packages.customPlanDesc") }}
      </p>

      <div class="grid grid-cols-1 lg:grid-cols-12 items-center my-6">
        <div class="lg:col-span-2 relative">
          <label class="absolute -top-10 font-semibold text-gray-100"
                 for="custom-plan-mine-power">
            {{ $t("minePower") }}
          </label>

          <base-input id="custom-plan-mine-power"
                      v-model.number="minePower"
                      autocomplete="off"
                      name="minePower"
                      @change="minePowerChanged">
            <template #prepend>
              <span class="-mx-4">TH/s</span>
            </template>
          </base-input>
        </div>

        <div class="text-5xl font-light text-gray-400 justify-self-center">
          <span>=</span>
        </div>

        <div class="lg:col-span-2 relative">
          <label class="absolute -top-10 font-semibold text-gray-100"
                 for="custom-plan-dollar-price">
            {{ t("dollarPriceTitle") }}
          </label>
          <base-input id="custom-plan-dollar-price"
                      v-model.number="dollarPrice"
                      name="dollarPrice"
                      @change="dollarChanged">
            <template #prepend>
              <span class="-mx-4">$</span>
            </template>
          </base-input>
        </div>

        <div class="text-5xl font-light text-gray-400 justify-self-center">
          <span>=</span>
        </div>

        <div class="lg:col-span-2 relative">
          <label class="absolute -top-10 font-semibold text-gray-100"
                 for="custom-plan-toman-price">
            {{ t("tomanPriceTitle") }}
          </label>
          <base-input id="custom-plan-toman-price"
                      v-model.number="tomanPrice"
                      class="px-1.5"
                      name="tomanPrice"
                      @change="tomanChanged">
            <template #prepend>
              <span class="text-xs -mx-5">{{ $t("toman") }}</span>
            </template>
          </base-input>
        </div>

        <div class="text-4xl font-light text-gray-400 justify-self-center">
          <span>+</span>
        </div>

        <div class="lg:col-span-3 flex flex-col gap-6">
          <div class="flex flex-col">
            <p class="text-gray-100">912 {{ $t("day") }}</p>
            <p class="text-sm font-light text-gray-300">{{ $t("mineBitcoin") }}</p>
          </div>
          <div class="flex flex-col">
            <p class="text-gray-100">SHA256</p>
            <p class="text-sm font-light text-gray-300">{{ $t("mineAlgorithm") }}</p>
          </div>
          <div class="flex flex-col">
            <p class="text-gray-100">0 {{ $t("toman") }}</p>
            <p class="text-sm font-light text-gray-300">{{ $t("maintenanceFee") }}</p>
          </div>
        </div>
      </div>

      <div>
        <base-button :disabled="(!minePower) || submitting"
                     :loading="submitting"
                     class="btn-md font-bold bg-green-400 hover:bg-green-500 px-16"
                     icon="fa fa-check"
                     variant="success"
                     @click="addToCart">
          {{ $t("pages.home.packages.startNow") }}
        </base-button>
      </div>
    </div>

    <Overlay :show="loading"
             class="!z-10 rounded-xl"
             type="absolute">
      <div class="flex justify-center items-center w-full h-full">
        <Spinner />
      </div>
    </Overlay>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import Overlay from "@/components/utils/common/Overlay";

export default {
  name: "CustomPricingPlan",
  components: {
    Overlay,
  },
  setup() {
    const { t } = useI18n({
      messages: {
        fa: {
          dollarPriceTitle: "قیمت دلاری",
          tomanPriceTitle: "قیمت تومانی",
        },
        en: {
          dollarPriceTitle: "Price (Dollar)",
          tomanPriceTitle: "Price (Toman)",
        },
      },
    });

    return {
      t,
    };
  },
  data() {
    return {
      loading: false,
      submitting: false,
      minePower: "",
      dollarPrice: "",
      tomanPrice: "",
    };
  },
  methods: {
    minePowerChanged(event) {
      const value = event.target.value || 0;

      this.fetchPrice(`hash_power=${value}`)
        .then(({ priceInRial, priceInDollar }) => {
          this.dollarPrice = priceInDollar;
          this.tomanPrice = priceInRial;
        });
    },
    dollarChanged(event) {
      const value = event.target.value || 0;

      this.fetchPrice(`dollar_amount=${value}`)
        .then(({ hashPower, priceInRial }) => {
          this.tomanPrice = priceInRial;
          this.minePower = hashPower;
        });
    },
    tomanChanged(event) {
      const value = event.target.value || 0;

      this.fetchPrice(`rial_amount=${value}`)
        .then(({ hashPower, priceInDollar }) => {
          this.minePower = hashPower;
          this.dollarPrice = priceInDollar;
        });
    },
    fetchPrice(param) {
      return new Promise((resolve) => {
        this.loading = true;

        ApiService.get(`check-custom-plan?${param}`)
          .then(({ data }) => {
            const { hash_power, price_by_rial, price_by_dollar } = data.data;

            const result = {
              hashPower: hash_power,
              priceInRial: price_by_rial,
              priceInDollar: price_by_dollar,
            };

            resolve(result);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    addToCart() {
      this.submitting = true;

      const payload = {
        hash_power: this.minePower,
        refresh: 0,
      };

      ApiService.post("add-to-cart", payload)
        .then(() => {
          this.$router.push({ name: "Cart" });
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>
