export default function() {
  function formatNumber(number, minFractionDigits = 0, maxFractionDigits = 8) {
    const options = {
      minimumFractionDigits: minFractionDigits,
      maximumFractionDigits: maxFractionDigits,
    };

    const numberFormat = new Intl.NumberFormat("en-US", options);

    // return numberFormat.format(number).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.0+$)/, "$1");
    return numberFormat.format(number).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, "$1");
  }

  return {
    formatNumber,
  };
}
