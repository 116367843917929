<template>
<BaseDialog
    :open="openModal"
    @closed="openModal=false"
    closeable
>
  <div class="flex flex-col">
    <span class="font-bold mb-3">{{text1}}</span>
    <span class="text-justify leading-7">{{text2}}</span>
    <span class="text-justify leading-7">{{text3}}</span>
    <span class="text-justify leading-7 mt-3">{{text4}}</span>
    <span class="text-justify leading-7">{{text5}}</span>
  </div>

  <template #footer>
    <div class="flex justify-end gap-2">
      <base-button class="px-6"
                   type="button"
                   variant="success"
                   @click="readText()">
        {{ $t("pages.home.ISaw") }}
      </base-button>
    </div>
  </template>

</BaseDialog>
</template>

<script>
import BaseDialog from "@/components/utils/dialogs/BaseDialog";

export default {
  name: "NewsModal",

  data() {
    return {
      openModal: false,
      text1: 'به نام او که هرچه هست از اوست',
      text2: 'ضمن عرض تبریک ، بدینوسیله به استحضار عموم و مشترکین عزیز شرکت تدبیر افزار پژواک ( تپکو ) می رساند مفتخریم اعلام کنیم اولین فارم رمز ارز شرکت در استان اصفهان در منطقه فریدن در اردیبهشت ماه سال جاری به مرحله بهره برداری خواهد رسید.',
      text3: 'بی شک این آغاز راهی پر فراز و نشیب است ، اما همگی می دانیم که با تلاش هیچ چیزی غیر ممکن نیست و صبوری و درک شما عزیزان همواره باعث دلگرمی ما بوده است بی تردید قدردان شما گرامیان هستیم.',
      text4: 'با تشکر و احترام',
      text5: 'مدیریت شرکت تدبیر افزار پژواک'
    }
  },

  components: {
    BaseDialog
  },

  methods: {
    readText() {
      window.localStorage.setItem('newsmodal_read', 1)
      this.openModal = false;
    }
  },

  created() {
    if(!window.localStorage.getItem('newsmodal_read')) {
      this.openModal = true;
    }
  }
}
</script>

<style scoped>

</style>