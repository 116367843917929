<template>
  <span
    :class="[variantClass]"
    class="text-xs font-semibold rounded px-2 py-1"
  >
    <slot />
  </span>
</template>

<script>
import ColorVariants from "@/core/enums/ColorVariants";

export default {
  name: "BaseBadge",
  data() {
    return {
      variantClasses: {
        [ColorVariants.SUCCESS]: "bg-green-600 text-green-50",
        [ColorVariants.DANGER]: "bg-red-600 text-red-50",
        [ColorVariants.SECONDARY]: "bg-gray-600 text-gray-50",
        [ColorVariants.INFO]: "bg-blue-600 text-blue-50",
        [ColorVariants.WARNING]: "bg-yellow-500 text-yellow-50",
      },
    };
  },
  props: {
    variant: {
      type: String,
      required: true,
    },
  },
  computed: {
    variantClass() {
      return this.variantClasses[this.variant] || this.variantClasses[ColorVariants.SECONDARY];
    },
  },
};
</script>
