import { createI18n } from "vue-i18n";

export const locales = [
  {
    locale: "fa",
    name: "فارسی",
    flag: "iran.svg",
    direction: "rtl",
  },
  {
    locale: "en",
    name: "English",
    flag: "us.svg",
    direction: "ltr",
  },
];

const datetimeFormats = {
  fa: {
    short: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    long: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
  },
  en: {
    short: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    long: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
  },
};

const messages = {
  fa: {
    makhhash: "ماخ‌هش",
    makhmining: "ماخ‌ماینینگ",
    account: "حساب کاربری",
    authentication: "احراز هویت",
    login: "ورود",
    register: "ثبت‌نام",
    passwordReset: "بازیابی کلمه عبور",
    error404: "ارور 404",
    error500: "ارور 500",
    address: "آدرس",
    email: "ایمیل",
    password: "رمز عبور",
    logout: "خروج",
    token: "توکن",

    // Header
    home: "خانه",
    mining: "استخراج",
    packages: "پکیج‌ها",
    cloudMining: "کلود ماینینگ",
    support: "پشتیبانی",
    about: "درباره ما",
    social: "رسانه",
    userManual: "راهنمای عضویت",
    blogArticles: "مقالات",
    blogArticlesShow: "جزئیات مقاله",
    mineCalculator: "محاسبه سود استخراج",

    changePassword: "تغییر رمز عبور",
    terms: "قوانین",
    transactionDetails: "اطلاعات پرداخت",
    packageEstimation: "تخمین پکیج",
    comments: "نظرات",
    dashboardTitle: "داشبورد",
    emailVerification: "فعالسازی ایمیل",
    verification: "احراز هویت",
    balance: "بالانس",
    wallets: "لیست کیف پول‌ها",
    createWallet: "ایجاد کیف پول جدید",
    dailyMineReport: "گزارش استخراج روزانه",
    transactions: "تراکنش‌ها",
    withdrawals: "درخواست‌های برداشت",
    newWithdraw: "درخواست برداشت جدید",
    plans: "لیست طرح‌ها",
    cart: "سبد خرید",
    bill: "فاکتور پرداخت",
    deposit: "افزایش اعتبار",
    depositList: "فهرست واریزها",
    twoFA: "شناسایی دوعاملی",
    reward: "پاداش معرفی",
    ticketsList: "لیست تیکت‌ها",
    notifList: "لیست اعلان ها ",
    ticketShow: "مشاهده تیکت",
    newTicket: "تیکت جدید",
    notifications: "اعلان ها",

    // Footer
    pezhvak: "شرکت مهندسی مشاور تدبیر افزار پژواک",
    registrationNumber: "شماره ثبت",

    // General
    day: "روز",
    month: "ماه",
    isfahan: "اصفهان",
    faq: "سوالات متداول",
    amount: "مبلغ",
    currency: "ارز",
    network: "شبکه",
    time: "تاریخ",
    id: "شناسه",
    name: "نام",
    lastname: "نام خانوادگی",
    sex: "جنسیت",
    nationalCode: "کد ملی",
    idCard: "کارت ملی",
    status: "وضعیت",
    active: "وضعیت",
    issueDate: "تاریخ ایجاد",
    actions: "عملیات",
    description: "شرح",
    title: "عنوان",
    cancel: "لغو",
    confirm: "تأیید",
    remove: "حذف",
    mobile: "تلفن همراه",
    verificationId: "کارت شناسایی",
    submit: "ثبت",
    loadingText: "در حال دریافت اطلاعات...",
    send: "ارسال",
    phoneNumber: "شماره تلفن ثابت",
    continue: "ادامه",
    trackCode: "شماره پیگیری",
    megawatt: "مگاوات",
    view: "مشاهده",
    subject: "موضوع",
    priority: "اولویت",
    createdAt: "زمان ایجاد",
    department: "دپارتمان",
    attachment: "پیوست",
    downloadAttachment: "دانلود پیوست",
    verifyCode: "کد تأیید",
    twoFACode: "کد تأیید دو مرحله‌ای",
    transactionNo: "شماره تراکنش",
    gateway: "پذیرنده",

    // Mining
    minePower: "قدرت استخراج",
    mineAlgorithm: "الگوریتم استخراج",
    mineBitcoin: "استخراج بیت‌کوین",
    hashPower: "قدرت فعال",
    mineUnit: "تراهش بر ثانیه",

    // Financial
    bitcoinPrice: "قیمت بیت‌کوین",
    dollarPrice: "قیمت دلاری",
    rialPrice: "قیمت ریالی",
    toman: "تومان",
    rial: "ریال",
    dollar: "دلار",
    bitcoin: "بیت‌کوین",
    tether: "تتر",
    maintenanceFee: "هزینه نگهداری",
    rialWallet: "کیف پول ریالی",
    bitcoinWallet: "کیف پول بیت‌کوینی",
    cryptoWallet: "کیف پول ارزی",
    accountNumber: "شماره حساب",
    wallet: "کیف پول",
    walletName: "عنوان والت",
    walletAddress: "آدرس والت",
    checkout: "پرداخت",
    price: "قیمت",

    pages: {
      home: {
        hero: {
          title: "ایده درآمدی خود را عملی کنید",
          subtitle: "به جمع استخراج کنندگان قانونی بیت کوین در ایران بپیوندید",
          minedBitcoin: "بیت‌کوین استخراج‌شده",
          minePower: "قدرت استخراج ماخ‌ماینینگ",
          bitcoinPrice: "قیمت بیت‌کوین",
        },
        features: {
          titleOne: "تجهیزات ماینینگ مورد نیاز شما از قبل آماده شده اند",
          descOne:
            "چرا اجاره‌ی دستگاه استخراج ابری از راه اندازی محدود آن به صرفه تر است؟",
          titleTwo:
            "به جای خرید ارزهای دیجیتال، در استخراج آنها سرمایه گذاری کنید",
          descTwo:
            "با استفاده از یک رایانه با توان پردازشی بالا و سخت افزار مناسب میتوان بیت کوین تولید کرد",
          titleThree:
            "درآمد استخراج خود را بدون کسر هزینه های نگهداری به حساب شخصی واریز کنید",
          descThree:
            "ما به شما می آموزیم چگونه درآمد روزانه خود را از مراجع معتبر استعلام کنید",
        },
        comments: {
          title: "سرویس ابری ماخ ماینینگ از دید کاربران",
          seeMore: "مشاهده نظرات بیشتر",
        },
        packages: {
          title: "اشتراک استخراج ابری ماخ ماینینگ",
          noMaintenanceFee: "بدون هیچ‌گونه هزینه نگهداری",
          noMaintenanceFeeDesc:
            "تنها هزینه شما همان چیزی است که برای قدرت هش (قدرت استخراج) پرداخت می‌کنید. در این طرح هیچ‌گونه هزینه نگهداری در نظر گرفته نشده است.",
          estimate: "چگونه پتانسیل استخراج پکیج ها را تخمین بزنیم؟",
          startNow: "شروع کنید",
          customPlanTitle: "سرویس دلخواه شما",
          customPlanDesc:
            "شما می‌توانید با محاسبه‌گر ما سرویس دلخواه خود را بسازید و آن را سفارش دهید",
          buyPlan: "خرید سرویس",
        },
        ISaw: 'مشاهده کردم'
      },
      mining: {
        subtitle: " عملیات امن کردن شبکه بلاکچین",
        datacenter: "دیتاسنتر فعال",
        electricity: "برق",
        description:
          "استخراج بیت کوین از طریق اثبات کار که یک فرآیند اعتبار سنجی تمام عیار است، همزمان شبکه را ایمن می کند، تراکنش ها را پردازش می کند، اجماع را تسهیل می کند و بیت کوین جدید را استخراج می کند. اثبات کار یک انگیزه اقتصادی باورنکردنی برای یک ماینر ایجاد می کند تا مورد اعتماد باقی بماند و ساختاری است که از بسیاری جهات اعتماد به شبکه بیت کوین را تقویت می کند." +
          "شبکه بیت کوین با استخراج ایمن می شود، فرآیندی که تراکنش های موجود در شبکه را تایید می کند و همزمان بیت کوین جدید را استخراج می کند. ماینینگ همچنین تضمین می کند که هیچ نهادی کنترل بلاکچین یا پروتکل حاکم بر آن را در دست ندارد، و بیت کوین را در برابر کنترل (یا بسته شدن) توسط دولت یا مقام مرکزی مقاوم می کند." +
          "استخراج بیت کوین یک پیشرفت مهم در علم کامپیوتر است که به طور همزمان بیت کوین را اختراع می کند و تراکنش ها را در شبکه بیت کوین تایید می کند." +
          " نحوه کار آن به این صورت است: رایانه‌های روی شبکه - گره‌هایی که به عنوان «ماینرها» شناخته می‌شوند - برای حل یک پازل محاسباتی فشرده و اثبات کار (PoW) رقابت می‌کنند." +
          ' اولین ماینری که راه حلی را پیدا می کند که توسط اکثر ماینرها پذیرفته شده باشد، مجاز است یک "بلاک" از تراکنش های جدید را در "بلاک چین" بنویسد. در ازای تلاش‌هایش، مقداری از بیت‌کوین از پیش تعیین‌شده به نام «پاداش بلاک» به او تعلق می‌گیرد.' +
          " این مسابقه تقریباً 10 دقیقه طول می کشد و هنگامی که راه حلی پیدا شد و توسط 51٪ یا بیشتر ماینرها پذیرفته شد، دوباره شروع می شود." +
          "داده های تراکنش یکی از قطعاتی است که ماینرها برای حل پازل PoW از آن استفاده می کنند. اگر یک ماینر از تراکنش های معتبر استفاده نکند، سایر ماینرها نمی توانند در مورد راه حل او به توافق برسند زیرا از مجموعه داده های تراکنش متفاوتی کار می کنند. این بدان معناست که آنها به او اجازه نخواهند داد که یک بلاک جدید برای بلاک چین بنویسد و پاداش آن را برنده شود." +
          " در نتیجه تمام قدرت محاسباتی که او برای یافتن راه حل اختصاص داده بود بیهوده بوده است. " +
          "بنابراین، یک ماینر به شدت تشویق می شود که مورد اعتماد باقی بماند و از تراکنش های معتبر استفاده کند تا مبادا منابع گرانبهای خود را بیهوده خرج کند. " +
          "به این ترتیب، عملیات استخراج به امنیت شبکه بیت کوین نیز کمک می کند.",
        datacenters: {
          first: {
            title: "دیتاسنتر قزاقستان",
            desc: "ظرفیت استخراج این دیتاسنتر ماکزیمم 100 تراهش بر ثانیه میباشد. برق استفاده شده برق صنعتی است و به همین دلیل هزینه کمتری دارد.",
          },
          second: {
            title: "دیتاسنتر فریدن اصفهان",
            desc: "ظرفیت استخراج این دیتاسنتر ماکزیمم 100 تراهش بر ثانیه میباشد. برق استفاده شده برق صنعتی است و به همین دلیل هزینه کمتری دارد.",
          },
        },
      },
      packages: {
        hero: {
          title: " چگونه پتانسیل استخراج پکیج‌ها را تخمین بزنیم؟",
          warning: "توجه!",
          warningDesc:
            "ماشین حسابهای استخراج فقط به عنوان ابزاری اطلاعاتی و آموزشی طراحی شده اند و توصیه های سرمایه گذاری محسوب نمیشوند.اکیدا توصیه میکنیم قبل از تصمیم گیری ، محاسبات خود را انجام دهید.نتایج ارائه شده توسط ماشین حسابها فرضی است و به احتمال قوی نمیتواند تولید واقعی بیت کوین را منعکس کند. شرکت تدبیرافزار پژواک و شرکت های وابسته آن هیچگونه مسئولیتی در قبال عواقب تصمیمات یا اقداماتی که با اتکا به اطلاعات ارائه شده در اینجا یا در نتیجه آن انجام شده ندارند.",
        },
        tabs: {
          one: {
            title: "جنبه‌های اصلی ماینینگ",
            miningPotentialTitle: "پتانسیل استخراج کوین",
            miningPotentialDesc:
              "استخراج موفقیت آمیز رمز ارزها بر اساس عوامل مختلف زمینه ای ایجاد میشود. در اینجا میخواهیم با ایجاد آگاهی از متغیر های ذاتی و نوسانات احتمالی آنها ، این را با مثالی از نحوه کارکرد تئوری روزانه بیت کوین نشان دهیم. " +
              "عوامل تاثیر گذار بر پتانسیل تولید بیت کوین به سه دسته تقسیم می شود: 1) تجهیزات ایجاد هش ریت (ماینر و ...) 2) عوامل بازار (قیمت و هش ریت شبکه/ سختی استخراج) " +
              "3) هزینه های تولید (برق، تعمیرات و نگهداری و ...) ماخ ماینینگ بر روی عوامل دسته ی اول و سوم کنترل و تسلط دارد، در عین حال عوامل بازار برای هیچ استخراج کننده ای قابل پیش بینی نیست. برای مثال قیمت بین کوین بصورت لحظه ای و هش ریت شبکه آن هر دو هفته یکبار تغییر می کند.",
            networkHashRate: "هش‌ریت شبکه",
            networkDifficulty: "سختی شبکه",
            formulaTitle: " فرمول پتانسیل تولید بیت کوین",
            formula: "قیمت بیت کوین x سختی شبکه x هش ریت",
            miningFlowTitle: "حرکت استخراج",
            miningFlowDesc:
              "در رابطه با تولید بیت کوین، از طریق استخراج سه متغیر وجود دارد : قیمت بیت کوین ، هش ریت شبکه و سختی شبکه",
            firstScenario: "سناریو اول",
            firstScenarioTitle: "افزایش قیمت بیت‌کوین",
            firstScenarioDesc:
              "وقتی قیمت بیت کوین افزایش میابد، انگیزه استخراج برای استخراج کنندگان افزایش میابد. به طور کلی بیشتر شدن استخراج کنندگان به معنی افزایش هش ریت شبکه است که به معنای افزایش سختی شبکه و در نتیجه سهم کمتر هر ماینر در آن دوره است. اما این معمولا در طی یک دوره زمانی اتفاق می افتد. برنامه ریزی و ساخت فارم های استخراج ماه ها به طول می انجامد و مستلزم سرمایه گذاری اولیه بالایی است. به این معنی که هش ریت جدید به تدریج و با تاخیر زمانی آنلاین میشود.",
            secondScenario: "سناریو دوم",
            secondScenarioTitle: "کاهش قیمت بیت‌کوین",
            secondScenarioDesc:
              "هنگامیکه قیمت بیت کوین کاهش میابد ، انگیزه استخراج کاهش میابد. بسته به هزینه های نگهداری استخراج کنندگان و شرایط اقتصادی، ممکن است برخی از استخراج کنندگان با ضرر روبه رو شوند.(عموما اکثر استخراج کنندگان که پشتوانه مالی کمی دارند برای پرداخت هزینه های جاری مجبور به فروش بیت کوین های تولیدی هستند که اگر قیمت بیت کوین پایین باشد با مشکل اقتصادی مواجه میشوند) و در نتیجه ماینرهای خود را خاموش میکنند که منجر به افت هش ریت شبکه میشود. کاهش سختی شبکه منجر به افزایش سهم تولید ماینرهای فعال در شبکه میشود و در صورت بالا رفتن قیمت بیت کوین، انباشت بیشتر آن میتواند به نفع استخراج کنندگان باشد که بازیگران بلند مدت این صنعت هستند.",
            thirdScenario: "سناریو سوم",
            thirdScenarioTitle: "ثابت ماندن قیمت بیت‌کوین",
            thirdScenarioDesc:
              "وقتی قیمت بیت کوین برای مدت زمان طولانی تری ثابت بماند، هش ریت شبکه و سختی شبکه میتواند در یک سطح خنثی که به آن CETERISPARIBUS میگویند باقی بماند و منجر به تولید مداوم بیت کوین میشود.\n" +
              "با این حال برخی از رویدادها مثل ورود استخراج کنندگان جدید و پیشرفته و همینطور خروج استخراج کنندگان قدیمی و مستهلک میتواند سختی شبکه را تغییر دهد.\n" +
              "این سیستم (شبکه بیت کوین) به کار آمدترین و متعهد ترین استخراج کنندگان خود پاداش میدهد.\n" +
              "در مورد استخراج ابری، فاکتور مهمی که بر پویایی استخراج تاثیر میگذارد ، هزینه تعمیر و نگهداری است.\n" +
              "هر استخراج کننده ای که بتواند هزینه های نگهداری خود را به حداقل برساند، حاشیه سود بیشتری بدست خواهد آورد.\n",
          },
          two: {
            title: "تخمین تولید بیت‌کوین",
            sectionOneTitle: "قسمت اول",
            sectionOneDesc:
              "محاسبه تولید ناخالص استخراج بیت کوین:\n" +
              "1) یک ماشین حساب استخراج انتخاب کنید مثل https://whattomine.com/coins/1-btc-sha-256\n" +
              "2) مقدار قدرت استخراج خریداری شده از ماخ را درHash rate  وارد کنید، برای مثال اگر قدرت استخراج شما TH/s10 است وارد کنید Gh/s 10000 ))\n" +
              "3) هر دو قسمت “power” و “cost” را روی صفر تنظیم کنید. (قبلا هزینه نگهداری پکیج استخراج بیت کوین، در قیمت اولیه لحاظ شده است.)\n" +
              "4) برای فعال کردن سختی شبکه، در قسمت “Difficulty” روی گزینه “Enable” کلیک کنید.\n" +
              "5) در کادر سمت چپ صفحه عدد سختی شبکه 7 روز گذشته (Difficulty 7 days) را کپی و در قسمت سختی ماشین حساب جای گذاری کنید.\n" +
              "6) روی گزینه محاسبه (calculate) کلیک کنید.\n" +
              "7) پس از محاسبه، صفحه را کمی پایین بکشید و در جدول تخمین پاداش (Estimate Reward) در ردیف روزانه (Day) و ستون تخمین پاداش (Est.Rewards) مقدار بیت کوین استخراج شده در یک روز را برای پکیج خود ببینید. \n" +
              "8) برای تخمین درآمد دلاری استخراج بیت کوین در همین جدول در ردیف روزانه (Day) و ستون (Rev.$) درآمد روزانه استخراج بیت کوین را ببینید.\n" +
              "توجه : محاسبه درآمد دلاری استخراج بیت کوین، با توجه به قیمت کنونی این ارز و متوسط سختی شبکه در 7 روز گذشته می باشد.\n" +
              "توصیه : سعی کنید مثل یک استخراج کننده حرفه ای رفتار کنید چون درآمد شما حاصل از تولید بیت کوین است. یک استخراج کننده حرفه ای به پایین بودن قیمت بیت کوین در زمان استخراج بیشترعلاقه دارد زیرا میتواند در سختی شبکه پایین بیت کوین بیشتری تولید کند و در قیمت بالاتری آنها را به شبکه تزریق کند. برداشت های مکرر روزانه و ماهیانه و تبدیل بیت کوین به ارزهای دیگر بدون برآورد و تحلیل مناسب میتواند حاشیه سود شما را از آن چیزی که پیش بینی میشود کاهش دهد. فروش به موقع بیت کوین های استخراج شده یا نگه داشتن آنها تا پایان قرارداد میتواند حاشیه سود حداکثری برای کاربران به وجود بیاورد.\n",
            sectionTwoTitle: "قسمت دوم",
            sectionTwoDesc:
              "استفاده از ماشین حسابهای محسابه درآمد استخراج بیت کوین",
            adviceTitle: "توصیه",
            adviceDesc:
              "ماشین حسابهای استخراج فقط به عنوان ابزاری اطلاعاتی و آموزشی طراحی شده اند و توصیه های سرمایه گذاری محسوب نمیشوند.اکیدا توصیه میکنیم قبل از تصمیم گیری ، محاسبات خود را انجام دهید.نتایج ارائه شده توسط ماشین حسابها فرضی است و به احتمال قوی نمیتواند تولید واقعی بیت کوین را منعکس کند.\n" +
              "شرکت تدبیرافزار پژواک و شرکت های وابسته آن هیچگونه مسئولیتی در قبال عواقب تصمیمات یا اقداماتی که با اتکا به اطلاعات ارائه شده در اینجا یا در نتیجه آن انجام شده ندارند.",
          },
        },
        pricing: {
          title: "اشتراک استخراج ابری ماخ ماینینگ",
        },
      },
      cloudMining: {
        hero: {
          title: "یک راه جدید برای کسب درآمد",
          subtitle: "با استخراج بیت‌کوین و استخراج ابری آن بیشتر آشنا شوید",
        },
        content: {
          whatsMiningTitle: "ماینینگ چیست؟",
          whatsMiningText:
            "ماینینگ یا استخراج رمز ارز دیجیتال فرآیندی است که درآن تراکنش ارز دیجیتال تایید می شود و به پایگاه داده یا همان بلاکچین افزوده می گردد که با تولید رمزارز همراه است و از طریق ایسیک یا کارت گرافیک انجام می شود. " +
            "مشکل ارزهای فیات، متمرکز بودن آنهاست، درصورتیکه استخراج ارزهای دیجیتال باعث می شود به صورت یک شبکه غیرمتمرکز و بدون کنترل و مدیریت یک نهاد مرکزی فعالیت نماید. \n" +
            "استخراج رمزارز از تکنولوژی های جدید محسوب شده و دستگاه های مربوط به آن فراتر از توان رایانه ای موجود است. دستگاه های استخراج در مدت زمانی حتی کمتر از یک سال بایستی بروز شوند تا سرعت پردازش و قدرت لازم را نسبت به سایر دستگاه های استخراج در سایر نقاط جهان را داشته باشند.\n",
          whyMakMiningTitle: "چرا ماخ‌ماینینگ؟",
          whyMakMiningText:
            "ماخ ماینینگ در تلاش است با ایجاد زیرساخت های مناسب، عملیات استخراج رمزارز کارآمد را برای سرمایه گذاری مطمئن در اختیار همگان قرار دهد. <br>" +
            "1. بالاترین میانگین درآمدی استخراج را تجربه کنید: ماخ ماینینگ بدون دخل و تصرف در درآمد شما و کسر حتی یک ساتوشی از درآمد روزانه شما، گزارش شفاف ، به روز و حرفه ای از درآمد روزانه استخراجتان به شما ارائه می دهد که می توانید آن را با بالاترین میانگین درآمد استخراج و استعلام از وبسایت های مرجع مقایسه کنید.<br>" +
            "2. استخراج ابری بدون هزینه نگهداری روزانه: تمام هزینه های پیش بینی شده برای استخراج موفق و مستمر شما در روز عقد قرارداد با محاسبه مقرون به صرفه به نفع کاربران، در قرارداد قید می گردد و پس از عقد قرارداد با شرکت تپکو، هیچ هزینه نگهداری اضافی دیگری پرداخت نخواهید کرد.<br>" +
            " 3. پنل کاربری حرفه ای با امکانات ویژه:<br> الف) گزارش روزانه استخراج<br> ب) برداشت آنی از موجودی استخراج شده<br> ج) دریافت مستقیم بیت کوین<br> د) پشتیبانی آنلاین و تلفنی",
          whereToStartTitle: "از کجا شروع کنیم؟",
          whereToStartText:
            "با ثبت نام و احراز هویت در وبسایت ماخ ماینینگ شما قادر خواهید بود پلن های استخراج بیت کوین ماخ ماینینگ را خریداری کرده و شروع به کسب درآمد کنید." +
            " شما می توانید برای کسب اطلاعات بیشتر و دریافت مشاوره رایگان از کارشناسان ما در ساعات اداری روزهای غیر تعطیل با شماره 91012373-021 تماس حاصل نمایید.",
        },
        cta: {
          title: "شیوه جدیدی از کسب درآمد را تجربه کنید",
          subtitle: "با ماخ‌ماینینگ",
          startNow: "شروع کنید",
          askQuestion1: "برای برطرف کردن سوالات خود به صفحه",
          askQuestion2: "مراجعه کنید.",
        },
      },
      support: {
        title: "ارتباط با پشتیبانی",
        subtitle:
          "در صورتی که برایتان مشکلی پیش آمده و یا سوالی دارید می توانید از روش های زیر با تیم پشتیبانی ماخ‌ماینینگ در ارتباط باشید",
        isfahanAddress: "آدرس اصفهان",
        tehranAddress: "آدرس تهران",
        onlineChatTitle: "چت آنلاین با ماخ‌ماینینگ",
        onlineChatText:
          "برای ارتباط برقرار کردن با تیم پشتیبانی ما می‌توانید از طریق چت آنلاین زیر پیام خود را برای ما ارسال نمایید و در صورت آنلاین بودن اپراتورهای ماخ، در اسرع وقت پاسخ شما داده خواهد شد.",
        startChat: "شروع چت",
        sendTicketTitle: "ارسال تیکت در پنل کاربری",
        sendTicketText:
          "یکی دیگر از راه‌های ارتباط برقرار کردن با تیم پشتیبانی ماخ، استفاده از سیستم تیکت موجود در پنل کاربری است. در صورتی که عضو هستید وارد پنل کاربری خود شوید. همچنین در صورتی که عضو نیستید می‌توانید به سادگی ثبت‌نام نمایید.",
        goToDashboard: "ورود به پنل کاربری",
      },
      about: {
        hero: {
          title: "درباره ماخ ماینینگ",
          subtitle: "عملیات امنیت شبکه بلاک‌چین",
        },
        text:
          "ماخ ماینینگ: بزرگترین پلتفرم استخراج رمزارز در ایران<br>" +
          "شرکت تدبیر افزار پژواک در سال 1398 با بهره برداری از مزرعه استخراج بیت کوین در کشور قزاقستان با ظرفیت 150,000 TH/S شروع به فعالیت نمود و برای اولین بار در ایران یک پلتفرم استخراج ابری واقعی با نام ماخ ماینینگ (ماخ ماینینگ فعلی) را به مردم عزیزمان ارائه نمود. در سال 1399 شرکت تپکو (تدبیر افزار پژواک) با ارائه پیشنهاد ایجاد یک شهرک رمزارز در استان اصفهان، موفق به اخذ مجوز استخراج رمزارز تا ظرفیت 2,300,000 TH/S شد که موجب فراتر رفتن چشم اندازهای سرویس ماخ ماینینگ در ایران شد.<br>" +
          "در حال حاضر این شرکت با تلاش شبانه روزی کارشناسان و متخصصین خود در صدد تکمیل فاز یک و شروع فازهای بعدی و احداث بزرگترین دیتاسنتر استخراج رمزارز در ایران است. به گزارش خبرگزاری های رسمی به نقل از دکتر امین خانجانی، مدیر عامل شرکت تپکو، در بهمن ماه 1400، پیشرفت پروژه شهرک رمزارز فریدن را 60% و پیشرفت فاز یک را 90% اعلام نمود که امید است با روشن شدن آن و افزایش ظرفیت های ماخ ماینینگ، خدمات استخراج ابری را به همه مردم ایران زمین ارائه دهیم.",
      },
    },

    dashboard: {
      // Main page titles
      title: "داشبورد",
      financial: "مالی",
      security: "امنیت",
      referralReward: "پاداش معرفی",
      verification: "احراز هویت",

      // General
      increaseBalance: "افزایش موجودی",

      // Financial
      amountInputPlaceholder: "مبلغ مورد نظر خود را وارد کنید...",

      // Ticket
      ticket: {
        title: "تیکت",
        priority: {
          low: "کم",
          medium: "متوسط",
          high: "زیاد",
        },
        status: {
          closed: "بسته شده",
          user_asked: "در انتظار پاسخ",
          admin_answered: "پاسخ داده شده",
        },
        department: {
          auth: "ثبت نام و ورود",
          commerce: "مالی",
          technical: "فنی",
          support: "پشتیبانی",
        },
      },
    },

    contact: {
      phone: "شماره تماس",
      addresses: {
        isfahan: "اصفهان، خیابان سعادت آباد، کوچه ی شماره ی 2، کوچه شهید رفیعیان، پلاک 3",
        tehran: "منطقه احمد قصیر (بخارست)، خیابان 91، پلاک 11، طبقه چهارم",
      },
    },
  },
  en: {
    makhhash: "Makhhash",
    makhmining: "MakhMining",
    account: "Account",
    authentication: "Authentication",
    login: "Login",
    register: "Register",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    address: "Address",
    email: "Email",
    password: "Password",
    logout: "Logout",
    token: "Token",

    // Header
    home: "Home",
    mining: "Mining",
    packages: "Packages",
    cloudMining: "Cloud Mining",
    support: "Support",
    about: "About us",
    social: "Social",
    userManual: "Registration Guide",
    blogArticles: "Articles",
    blogArticlesShow: "Blog Article Show",
    mineCalculator: "Mine Calculator",

    changePassword: "Change password",
    terms: "Terms",
    transactionDetails: "Transaction details",
    packageEstimation: "Package estimation",
    comments: "Comments",
    dashboardTitle: "Dashboard",
    emailVerification: "Email verification",
    verification: "KYC Verification",
    balance: "Balance",
    wallets: "Wallets",
    createWallet: "Create wallet",
    dailyMineReport: "Daily Mining Report",
    transactions: "Transactions",
    withdrawals: "Withdrawals",
    newWithdraw: "New Withdraw",
    plans: "Plans",
    cart: "Cart",
    bill: "Bill",
    deposit: "Deposit",
    depositList: "Deposit List",
    twoFA: "2-FA",
    reward: "Reward",
    ticketsList: "Tickets List",
    notifList: "Notif List",
    ticketShow: "View Ticket",
    newTicket: "New Ticket",
    notifications: "Notifications",

    // Footer
    pezhvak: "Tadbir Afzar Pezhvak Consulting Engineering Co.",
    registrationNumber: "Registration number",

    // General
    day: "day",
    month: "month",
    isfahan: "Esfahan",
    faq: "FAQ",
    amount: "Amount",
    currency: "Currency",
    network: "Network",
    time: "Date",
    id: "ID",
    name: "Name",
    lastname: "Lastname",
    sex: "Sex",
    nationalCode: "National Code",
    idCard: "ID Card",
    status: "Status",
    active: "Active",
    issueDate: "Issued date",
    actions: "Actions",
    description: "Description",
    title: "Title",
    cancel: "Cancel",
    confirm: "Confirm",
    remove: "Remove",
    mobile: "Mobile number",
    verificationId: "Verification ID",
    submit: "Submit",
    loadingText: "Loading...",
    send: "Send",
    phoneNumber: "Phone number",
    continue: "Continue",
    trackCode: "Track code",
    megawatt: "megawatt",
    view: "View",
    subject: "Subject",
    priority: "Priority",
    createdAt: "Created at",
    department: "Department",
    attachment: "Attachment",
    downloadAttachment: "Download attachment",
    verifyCode: "Verify Code",
    twoFACode: "2-FA Code",
    transactionNo: "Transaction No.",
    gateway: "Gateway",

    // Mining
    minePower: "Mine power",
    mineAlgorithm: "Mine algorithm",
    mineBitcoin: "Bitcoin mining",
    hashPower: "Hash power",
    mineUnit: "TH/s",

    // Financial
    bitcoinPrice: "Bitcoin price",
    dollarPrice: "Dollar price",
    rialPrice: "Rial price",
    toman: "Toman",
    rial: "Rial",
    dollar: "Dollar",
    bitcoin: "Bitcoin",
    tether: "USDT",
    maintenanceFee: "Maintenance fee",
    rialWallet: "Rial Wallet",
    bitcoinWallet: "BTC Wallet",
    cryptoWallet: "Crypto Wallet",
    accountNumber: "Account number",
    wallet: "Wallet",
    walletAddress: "Wallet address",
    walletName: "Wallet name",
    checkout: "Checkout",
    price: "Price",

    pages: {
      home: {
        hero: {
          title: "Realize your lucrative income idea",
          subtitle: "Join to the association of legal Bitcoin miners in IRR",
          minedBitcoin: "Mined Bitcoin",
          minePower: "Makh Mining mining power",
          bitcoinPrice: "Bitcoin price",
        },
        features: {
          titleOne: "Essential mining equipments are prepared for you",
          descOne:
            "What are the benefits of cloud mining services in comparison with solo mining?",
          titleTwo:
            "Be safer to invest in cloud mining to trade or hold cryptocurrencies",
          descTwo: "You will be able to mine Bitcoin using a powerful computer",
          titleThree:
            "Having your mining income in your wallet without any deductions.",
          descThree:
            "We introduce you the valid references to let you calculate your daily income",
        },
        comments: {
          title: "Makh mining cloud services in user's point of view ",
          seeMore: "see more comments",
        },
        packages: {
          title: "Makh cloud mining subscription",
          noMaintenanceFee: "Without any maintenance fee",
          noMaintenanceFeeDesc:
            "There is no other payment but the cost of your cloud mining subscription",
          estimate: "How to estimate the mining potential of packages?",
          startNow: "Start now",
          customPlanTitle: "Custom plan",
          customPlanDesc: "You are able to create your own customized plan",
          buyPlan: "Buy plan",
        },
      },
      mining: {
        subtitle: "Blockchain securing operation",
        datacenter: "Datacenter",
        electricity: "Electricity",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos distinctio, eveniet facere hic iusto modi quasi quisquam ratione vero. Ab, consectetur delectus libero non nulla officia quam. Aliquam, repellat, veritatis. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos distinctio, eveniet facere hic iusto modi quasi quisquam ratione vero. Ab, consectetur delectus libero non nulla officia quam. Aliquam, repellat, veritatis. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos distinctio, eveniet facere hic iusto modi quasi quisquam ratione vero. Ab, consectetur delectus libero non nulla officia quam. Aliquam, repellat, veritatis.",
        datacenters: {
          first: {
            title: "Kazakhstan Datacenter",
            desc: "ظرفیت استخراج این دیتاسنتر ماکزیمم 100 تراهش بر ثانیه میباشد. برق استفاده شده برق صنعتی است و به همین دلیل هزینه کمتری دارد.",
          },
          second: {
            title: "Isfahan Fereydan Datacenter",
            desc: "ظرفیت استخراج این دیتاسنتر ماکزیمم 100 تراهش بر ثانیه میباشد. برق استفاده شده برق صنعتی است و به همین دلیل هزینه کمتری دارد.",
          },
        },
      },
      packages: {
        hero: {
          title: "How to estimate the mining outcome for a specific plan?",
          warning: "Warning!",
          warningDesc:
            "These calculators are designed to be informational and educational tools only and do not constitute investment advice. We strongly recommend that you perform your own calculations before making any decisions. The results presented by these calculators are hypothetical and may not reflect the actual production of your own mining plans. Tapco and its affiliates are not responsible for the consequences of any decisions or actions taken in reliance upon or as a result of the information provided here.",
        },
        tabs: {
          one: {
            title: "Mining main aspects",
            miningPotentialTitle: "Coin mining potential",
            miningPotentialDesc:
              "Successful currency extraction is based on various underlying factors. Here we want to show this with an example of how bitcoin daily theory works by creating awareness of intrinsic variables and their possible fluctuations.",
            networkHashRate: "Network hash rate",
            networkDifficulty: "Network difficulty",
            formulaTitle: "Potential formula of Bitcoin mining",
            formula: "bitcoin price * network difficulty * hash rate",
            miningFlowTitle: "Mining flow",
            miningFlowDesc:
              "There are three variables related to the bitcoin production: Bitcoin price, hashrate of network and network dificulty",
            firstScenario: "First scenario",
            firstScenarioTitle: "Pumping Bitcoin price",
            firstScenarioDesc:
              "As the price of bitcoin bumps, so the motivation for the miners does. In general, more miners means an increase in hashrate of network, which means an increase in network difficulty, resulting by a smaller share of each miner in that period. But this usually happens over a period of time. Planning and building mining farms takes months and requires a large initial investment. This means that the new hashrate will go online gradually with delay.",
            secondScenario: "Second scenario",
            secondScenarioTitle: "Dumping Bitcoin price",
            secondScenarioDesc:
              "As the price of bitcoin bumps, so the motivation for the miners does. In general, more miners means an increase in hashrate of network, which means an increase in network difficulty, resulting by a smaller share of each miner in that period. But this usually happens over a period of time. Planning and building mining farms takes months and requires a large initial investment. This means that the new hashrate will go online gradually with delay.",
            thirdScenario: "Third scenario",
            thirdScenarioTitle: "Stable Bitcoin price",
            thirdScenarioDesc:
              "When the price of bitcoin remains stable for a longer period of time, hashrate of network and network dificulty can remain at a neutral level called CETERISPARIBUS, leading to persistent bitcoin production. However, some events such as the entry of new and advanced miners as well as the exit of old and worn out miners can change the difficulty of the network. This system (Bitcoin network) rewards to its most efficient and committed miners. In the case of cloud mining, an important factor that affects the dynamics of mining is the cost of maintenance. Any miner who can minimize their maintenance costs will get a higher profit margin. ",
          },
          two: {
            title: "Estimate Bitcoin mining amount",
            sectionOneTitle: "Section one",
            sectionOneDesc:
              "Calculating bitcoin mining production \n " +
              "Choose a mining calculator such as:\n" +
              "https: // whattomine, com / coins / 1-stc-shu-256\n" +
              "2) Enter the amount of mining power purchased from Makh at the Hash rate price (1000GH / S = Th / S1, for example if your mining power is TH / s10 Gh / s10000)\n" +
              '3) Set both "power" and "cost" parts to zero.\n' +
              "(Previously, the cost of maintaining a bitcoin mining package was included in the initial price.)\n" +
              '4) To activate the difficulty of the network, click on the "Enable" option.\n' +
              "5) In the left box of the page, copy the difficulty number of the last 7 days (Difficulty 7 days) and paste it in the difficulty section of the calculator.\n" +
              "6) Click on the calculate bottom.\n" +
              "7) After the calculation, drag the page down a bit and see the amount of bitcoins mined per day in your package in the Estimate Reward table in a day row and the estimate rewards column.\n" +
              "8) To estimate the dollar income of bitcoin mining, see the daily bitcoin mining income in the same table in a daily row and column (Rev. $).\n" +
              "Note: Calculating the dollar revenue of bitcoin mining is according to the current price of this cryptocurrency and the average difficulty of the network in the last 7 days." +
              "Tips: Try to behave like a professional miner, because your income comes from bitcoin production. A professional miner is more interested in the low price of bitcoin at the time of mining, because he can produces more bitcoins in the low network difficulty and inject them into the network at a higher price. Frequent daily and monthly withdrawals and transforming of bitcoins into other cryptocurrencies without proper estimation and analysis can reduce your profit margin from what is expected. On time sale of mined bitcoins or keeping them until the end of the contract can create maximum profit margins for users.",
            sectionTwoTitle: "Section two",
            sectionTwoDesc: "Using bitcoin mining revenue calculators ",
            adviceTitle: "Advice",
            adviceDesc:
              "The mining calculators are designed to be informational and educational tools only and do not constitute investment advice. We strongly recommend that you perform your own calculations before making any decisions. The results presented by these calculators are hypothetical and may not reflect the actual production of your own mining plans. Tapco and its affiliates are not responsible for the consequences of any decisions or actions taken in reliance upon or as a result of the information provided here. ",
          },
        },
        pricing: {
          title: "Makh cloud mining subscription",
        },
      },
      cloudMining: {
        hero: {
          title: "A new method of income",
          subtitle: "Know more about bitcoin and cloud mining",
        },
        content: {
          whatsMiningTitle: "What is mining?",
          whatsMiningText:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor et fuga nisi reprehenderit ut! Atque, cum excepturi illo optio quae similique soluta voluptas? Ab accusantium at fuga perspiciatis quisquam tenetur.",
          whyMakMiningTitle: "Why Makh-Mining?",
          whyMakMiningText:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor et fuga nisi reprehenderit ut! Atque, cum excepturi illo optio quae similique soluta voluptas? Ab accusantium at fuga perspiciatis quisquam tenetur.",
          whereToStartTitle: "Where to start?",
          whereToStartText:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor et fuga nisi reprehenderit ut! Atque, cum excepturi illo optio quae similique soluta voluptas? Ab accusantium at fuga perspiciatis quisquam tenetur.",
        },
        cta: {
          title: "There is a whole new way of income",
          subtitle: "With Makh-Mining",
          startNow: "Start now",
          askQuestion1: "If you have more questions, see",
          askQuestion2: "page",
        },
      },
      support: {
        title: "Contact support",
        subtitle:
          "If you have any problem, please contact us through either of following ways",
        isfahanAddress: "Isfahan address",
        tehranAddress: "Tehran address",
        onlineChatTitle: "Online chat with us",
        onlineChatText:
          "To contact our support team, you can send us your message via online chat below, and when our operators become online, you will be answered as soon as possible.",
        startChat: "Start Chat",
        sendTicketTitle: "Send ticket in dashboard",
        sendTicketText:
          "Another way to contact us is using the ticket system in your dashboard. if you have already signed up, please log in.",
        goToDashboard: "Sing in now",
      },
      about: {
        hero: {
          title: "About Makh-Mining",
          subtitle: "Blockchain securing operation",
        },
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet cum fugit laudantium nostrum nulla reprehenderit rerum totam? Autem dolorum labore maxime, natus quos repellat temporibus? Cupiditate exercitationem explicabo officiis qui? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet cum fugit laudantium nostrum nulla reprehenderit rerum totam? Autem dolorum labore maxime, natus quos repellat temporibus? Cupiditate exercitationem explicabo officiis qui? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet cum fugit laudantium nostrum nulla reprehenderit rerum totam? Autem dolorum labore maxime, natus quos repellat temporibus? Cupiditate exercitationem explicabo officiis qui? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet cum fugit laudantium nostrum nulla reprehenderit rerum totam? Autem dolorum labore maxime, natus quos repellat temporibus? Cupiditate exercitationem explicabo officiis qui?",
      },
    },

    dashboard: {
      // Main page titles
      title: "Dashboard",
      financial: "Financial",
      security: "Security",
      referralReward: "Referral reward",
      verification: "KYC Verification",

      // General
      increaseBalance: "Increase balance",

      // Financial
      amountInputPlaceholder: "Enter your desired amount...",

      // Ticket
      ticket: {
        title: "Ticket",
        priority: {
          low: "low",
          medium: "medium",
          high: "high",
        },
        status: {
          closed: "Closed",
          user_asked: "Waiting for answer",
          admin_answered: "answered",
        },
        department: {
          auth: "Authentication",
          commerce: "Commerce",
          technical: "Technical",
          support: "Support",
        },
      },
    },

    contact: {
      phone: "Phone number",
      addresses: {
        isfahan:
          "No. 88, Alley 14, Mardavij Tower Square, South Sheykh Sadouq St., Isfahan, Iran",
        tehran:
          "Ahmad Qusair District (Bucharest), 91st Street, No. 11, Fourth Floor",
      },
    },
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "fa",
  globalInjection: true,
  messages,
  datetimeFormats,
});

export default i18n;
