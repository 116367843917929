<template>
  <div>
    <BaseLabel v-if="label"
               :id="id">
      {{ label }}
    </BaseLabel>

    <input :id="id"
           class="file:me-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold
                  file:bg-green-50 file:text-green-700 hover:file:bg-green-100"
           v-bind="$attrs"
           type="file"
           @change="onFileChange">

    <FieldErrorMessage v-if="error.length > 0"
                       class="mt-2">
      {{ error }}
    </FieldErrorMessage>
  </div>
</template>

<script>
import BaseLabel from "@/components/utils/form/BaseLabel";
import FieldErrorMessage from "@/components/utils/form/FieldErrorMessage";

export default {
  name: "BaseInputFile",
  components: {
    BaseLabel,
    FieldErrorMessage,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
  },
  methods: {
    onFileChange(event) {
      this.$emit("update", event.target.files);
    },
  },
};
</script>
