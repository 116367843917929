import axios from "axios";
import JwtService from "@/core/services/JwtService";
import Notifier from "@/core/utils/NotifierProvider";
import store from "@/store";
import { PURGE_AUTH } from "@/store/enums/mutations";
import router from "@/router";

export const BASE_URL = process.env.VUE_APP_API_V1_BASE_URL;

const apiClient = axios.create();

function initInterceptors() {
  apiClient.interceptors.response.use(
    function (response) {
      if (!response.data.error) {
        return response;
      }

      Notifier.error(response.data.message);

      return Promise.reject(response);
    },
    function (error) {
      const status = error.response?.status || 500;
      const data = error.response?.data || "";

      // we can handle global errors here
      switch (status) {
        // authentication (token related issues)
        case 401: {
          store.commit(PURGE_AUTH);

          router.push({ name: "Login" });

          return Promise.reject(data);
        }

        // unprocessable
        case 422: {
          const errors = Object.values(data.errors);

          errors.forEach((errorItem) => {
            errorItem.forEach((errorMessage) => {
              Notifier.error(errorMessage);
            });
          });

          return Promise.reject(data);
        }

        // Too many requests
        case 429: {
          Notifier.error(
            "تعداد درخواست بیش از حد مجاز بوده است! لطفا دقایقی دیگر دوباره امتحان کنید."
          );
          return Promise.reject(error);
        }

        // generic api error (server related) unexpected
        default: {
          Notifier.error("خطایی رخ داده است. لطفا مجددا تلاش کنید.");
          router.push({ name: "Error" });
          return Promise.reject(error);
        }
      }
    }
  );
}

const ApiService = {
  init() {
    apiClient.defaults.baseURL = BASE_URL;

    initInterceptors();

    this.setLang("fa");
  },

  setLang(lang) {
    apiClient.defaults.headers.common["X-localization"] = lang;
  },

  setHeader() {
    apiClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return apiClient.get(resource, params).catch((error) => {
      throw new Error(`[MKH] ApiService ${error}`);
    });
  },

  get(resource) {
    return apiClient.get(`${resource}`).catch((error) => {
      throw new Error(`[MKH] ApiService ${error}`);
    });
  },

  post(resource, params) {
    return apiClient.post(`${resource}`, params);
  },

  update(resource, params) {
    return apiClient.put(`${resource}`, params);
  },

  put(resource, params) {
    return apiClient.put(`${resource}`, params);
  },

  delete(resource) {
    return apiClient.delete(resource).catch((error) => {
      throw new Error(`[MKH] ApiService ${error}`);
    });
  },
};

export default ApiService;
