<template>
  <div class="text-sm text-red-500 dark:text-red-400">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
};
</script>
