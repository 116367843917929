<template>
  <div class="overflow-x-auto px-2">
    <div class="align-middle inline-block min-w-full">
      <div class="overflow-hidden sm:rounded-lg">
        <table class="table w-full border-separate text-sm custom-table">
          <thead>
            <slot name="columns"></slot>
          </thead>

          <tbody class="space-y-2">
            <slot name="rows">
              <table-row>
                <table-cell class="text-center text-red-500"
                            colspan="42">
                  {{ t("noData") }}
                </table-cell>
              </table-row>
            </slot>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "TableComponent",
  setup() {
    const { t } = useI18n({
      messages: {
        fa: {
          noData: "هیچ اطلاعاتی برای نمایش وجود ندارد!",
        },
        en: {
          noData: "Nothing to show!",
        },
      },
    });

    return {
      t,
    };
  },
};
</script>

<style scoped>
.custom-table {
  border-spacing: 0 10px;
}
</style>
