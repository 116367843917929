<template>
  <div class="relative pb-2">
    <!--Title-->
    <component :is="as"
               :class="[title.theme ? title.theme : 'text-lg font-medium']"
               class="leading-relaxed">
      {{ title.text }}
    </component>

    <!--Subtitle-->
    <p v-if="subtitle && subtitle.text.length"
       :class="[subtitle.theme ? subtitle.theme : 'text-sm font-light text-gray-400']">
      {{ subtitle.text }}
    </p>

    <!--Divider-->
    <OneSideFadedDivider v-show="hasDivider"
                         width-class="w-2/3"
                         class="mt-4" />
  </div>
</template>

<script>
import OneSideFadedDivider from "@/components/utils/dividers/OneSideFadedDivider";

export default {
  name: "BaseHeading",
  components: {
    OneSideFadedDivider,
  },
  props: {
    title: {
      type: Object,
      required: true,
    },
    as: {
      type: String,
      default: "h2",
    },
    subtitle: {
      type: Object,
      default: null,
    },
    hasDivider: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
