import { ref, readonly } from "vue";
import ApiService from "@/core/services/ApiService";

const USD_IR = ref(0);
const BTC_USDT = ref(0);
const HPW_USDT = ref(0);
const USDT_SELL = ref(0);
const USDT_BUY = ref(0);
const BTC_WITHDRAW_FEE = ref(0);
const HPW_WITHDRAW_FEE = ref(0);

export default function () {
  function getCurrencyPrices() {
    return ApiService.get("currency-prices").then(({ data }) => {
      const prices = data.data;
      USD_IR.value = prices["dollarPrice"];
      BTC_WITHDRAW_FEE.value = prices["btc_withdraw_fee"];
      HPW_WITHDRAW_FEE.value = prices["hpw_withdraw_fee"];
      BTC_USDT.value = prices["btcPrice"];
      HPW_USDT.value = prices["hpw_price"];
      USDT_SELL.value = prices["tetherSellPrice"];
      USDT_BUY.value = prices["tetherBuyPrice"];
    });
  }

  return {
    getCurrencyPrices,
    USD_IR: readonly(USD_IR),
    BTC_USDT: readonly(BTC_USDT),
    HPW_USDT: readonly(HPW_USDT),
    USDT_SELL: readonly(USDT_SELL),
    USDT_BUY: readonly(USDT_BUY),
    BTC_WITHDRAW_FEE: readonly(BTC_WITHDRAW_FEE),
    HPW_WITHDRAW_FEE: readonly(HPW_WITHDRAW_FEE),
  };
}
