<template>
  <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
    <div class="flex flex-col justify-center">
      <h4 class="text-3xl md:text-4xl !leading-loose font-black text-center lg:text-start">
        {{ $t("pages.home.comments.title") }}
      </h4>

      <div class="w-full md:min-w-min my-5 md:mt-8 text-start">
        <base-link :to="{ name: 'Comments' }"
                   class="btn-md rounded-xl mt-4"
                   variant="primary">
          {{ $t("pages.home.comments.seeMore") }}
        </base-link>
      </div>
    </div>

    <div class="lg:col-span-2">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <VideoComment v-for="(item, index) in items"
                      :key="index"
                      :image="item.img" />
      </div>
    </div>
  </div>
</template>

<script>
//images
import image1 from "@/assets/images/pages/mining/cloud-mining-hero-bg.jpg";
import image2 from "@/assets/images/pages/mining/why-makhmining.jpg";
import image3 from "@/assets/images/pages/mining/where-to-start.jpg";
import image4 from "@/assets/images/pages/mining/what-is-mining.jpg";
import VideoComment from "@/components/main/user-comments/VideoComment";

export default {
  name: "index.vue",
  components: { VideoComment },
  data() {
    return {
      items: [
        {
          img: image1,
        },
        {
          img: image2,
        },
        {
          img: image3,
        },
        {
          img: image4,
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
