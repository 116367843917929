<template>
  <div>
    <div class="flex flex-wrap justify-between items-center">
      <div class="flex items-center text-green-500">
        <i class="fa fa-check text-2xl"></i>
        <p class="text-2xl font-semibold mx-2">{{ $t("pages.home.packages.noMaintenanceFee") }}</p>
      </div>

      <div>
        <base-link :to="{ name: 'PackageEstimation' }"
                   class="text-sm px-0"
                   variant="link-success">
          {{ $t("pages.home.packages.estimate") }}
        </base-link>
      </div>
    </div>

    <p class="text-sm dark:text-gray-300 mt-4">{{ $t("pages.home.packages.noMaintenanceFeeDesc") }}</p>

    <!--    <p>-->
    <!--      <base-link :to="{ name: 'PackageEstimation' }"-->
    <!--                 class="text-sm px-0"-->
    <!--                 variant="link-success">-->
    <!--        چگونه پتانسیل استخراج پکیج ها را تخمین بزنیم؟-->
    <!--      </base-link>-->
    <!--    </p>-->

    <!-- Loading spinner -->
    <div v-if="loading"
         class="flex justify-center mt-10">
      <Spinner />
    </div>

    <!-- Plans list -->
    <div v-else>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
        <PricingPlan v-for="(plan, index) in plans"
                     :key="index"
                     :plan="plan" />
      </div>

      <div class="mt-8"
           v-if="customPlan">
        <CustomPricingPlan />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import PricingPlan from "@/components/pricing/PricingPlan.vue";
import CustomPricingPlan from "@/components/pricing/CustomPricingPlan.vue";
import useCurrencyPrices from "@/core/composables/useCurrencyPrices";

export default {
  name: "PricingPlans",
  components: {
    PricingPlan,
    CustomPricingPlan,
  },
  props: {
    customPlan: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { getCurrencyPrices } = useCurrencyPrices();

    getCurrencyPrices();
  },
  data() {
    return {
      loading: false,
      plans: [],
      perPage: 10,
      page: 1,
      total: 1,
      sortBy: "created_at",
      sortOrder: "DESC",
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    onPaginate(page) {
      this.page = page;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      ApiService.get(`plans-list?page=${this.page}&limit=${this.perPage}&sortBy[${this.sortBy}]=${this.sortOrder}`)
        .then(({ data }) => {
          this.loading = false;
          const { data: items, total } = data.data;
          this.plans = items;
          this.total = total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
