<template>
  <th
    scope="col"
    class="font-normal text-start px-4 py-3"
  >
    {{ label }}
  </th>
</template>

<script>
export default {
  name: "TableColumn",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
};
</script>
