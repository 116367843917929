// import { nextTick } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import store from "@/store/index";
import { VERIFY_AUTH } from "@/store/enums/actions";

import Home from "@/views/main/Home.vue";

import Progress from "@/core/plugins/ProgressWrapper";

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/AppLayoutHome.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
        meta: { title: "home" },
      },
      {
        path: "/about",
        name: "About",
        component: () => import("@/views/main/About.vue"),
        meta: { title: "about" },
      },
      {
        path: "/mining",
        name: "Mining",
        component: () => import("@/views/main/Mining.vue"),
        meta: { title: "mining" },
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("@/views/auth/Login.vue"),
        meta: { title: "login" },
      },
      {
        path: "/register",
        name: "Register",
        component: () => import("@/views/auth/Register.vue"),
        meta: { title: "register" },
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        component: () => import("@/views/auth/ResetPassword.vue"),
        meta: { title: "changePassword" },
      },
      {
        path: "/change-password",
        name: "ChangePassword",
        component: () => import("@/views/auth/ChangePassword.vue"),
        meta: { title: "changePassword" },
      },
      {
        path: "/terms",
        name: "Terms",
        component: () => import("@/views/main/Terms.vue"),
        meta: { title: "terms" },
      },
      {
        path: "transaction-callback",
        name: "Callback",
        component: () => import("@/views/main/TransactionCallback.vue"),
        meta: { title: "transactionDetails" },
      },
      {
        path: "package-estimation",
        name: "PackageEstimation",
        component: () => import("@/views/main/PackageEstimation.vue"),
        meta: { title: "packageEstimation" },
      },
      {
        path: "support",
        name: "Support",
        component: () => import("@/views/main/Support.vue"),
        meta: { title: "support" },
      },
      {
        path: "comments",
        name: "Comments",
        component: () => import("@/views/main/Comments.vue"),
        meta: { title: "comments" },
      },
      {
        path: "cloud-mining",
        name: "CloudMining",
        component: () => import("@/views/main/CloudMining.vue"),
        meta: { title: "cloudMining" },
      },
      // {
      //   path: "/blog",
      //   name: "Blog",
      //   component: () => import("@/views/main/Articles.vue"),
      //   meta: { title: "blogArticles" },
      // },
      // {
      //   path: "/blog/:id",
      //   name: "BlogShow",
      //   component: () => import("@/views/main/ArticleShow.vue"),
      //   meta: { title: "blogArticlesShow" },
      // },
      {
        path: "/mine-calculator",
        name: "MineCalculator",
        component: () => import("@/views/main/MineCalculator.vue"),
        meta: { title: "mineCalculator" },
      },
    ],
  },
  {
    path: "/dashboard",
    component: () => import("@/layouts/DashboardLayout.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: () => import("@/components/utils/EmptyRouterView.vue"),
        meta: { requiresEmail: true },
        children: [
          {
            path: "",
            name: "Dashboard",
            component: () => import("@/views/dashboard/Dashboard.vue"),
            meta: { title: "dashboardTitle" },
          },
          {
            path: "email-verification",
            component: () => import("@/views/dashboard/EmailVerification.vue"),
            name: "EmailVerification",
            meta: { title: "emailVerification" },
          },
          {
            path: "verification",
            component: () => import("@/views/dashboard/Verification.vue"),
            name: "Verification",
            meta: { title: "verification" },
          },
          {
            path: "commerce",
            component: () => import("@/views/dashboard/commerce/Commerce.vue"),
            children: [
              {
                path: "balance",
                name: "Balance",
                component: () =>
                  import("@/views/dashboard/commerce/Balance.vue"),
                meta: { title: "balance" },
              },
              {
                path: "wallets-list",
                name: "Wallets",
                component: () =>
                  import("@/views/dashboard/commerce/wallet/WalletsList.vue"),
                meta: { title: "wallets" },
              },
              {
                path: "create-wallet",
                name: "CreateWallet",
                component: () =>
                  import("@/views/dashboard/commerce/wallet/CreateWallet.vue"),
                meta: { title: "createWallet", requiresIdentification: true },
              },
              {
                path: "mine-report",
                name: "DailyMineReport",
                component: () =>
                  import("@/views/dashboard/commerce/DailyMineReport.vue"),
                meta: { title: "dailyMineReport" },
              },
              {
                path: "transactions",
                name: "Transactions",
                component: () =>
                  import("@/views/dashboard/commerce/Transactions.vue"),
                meta: { title: "transactions" },
              },
              {
                path: "withdrawal-requests",
                name: "Withdrawals",
                component: () =>
                  import("@/views/dashboard/commerce/WithdrawalList.vue"),
                meta: { title: "withdrawals" },
              },
              {
                path: "new-withdrawal-request",
                name: "NewWithdraw",
                component: () =>
                  import("@/views/dashboard/commerce/NewWithdraw.vue"),
                meta: { title: "newWithdraw", requiresIdentification: true },
              },
              {
                path: "plans",
                name: "Plans",
                component: () =>
                  import("@/views/dashboard/commerce/PlansList.vue"),
                meta: { title: "plans", requiresIdentification: true },
              },
              {
                path: "cart",
                name: "Cart",
                component: () => import("@/views/dashboard/commerce/Cart.vue"),
                meta: { title: "cart", requiresIdentification: true },
              },
              {
                path: "bill/:id",
                name: "Bill",
                component: () => import("@/views/dashboard/commerce/Bill.vue"),
                meta: { title: "bill", requiresIdentification: true },
              },
              {
                path: "deposit",
                name: "Deposit",
                component: () =>
                  import("@/views/dashboard/commerce/deposit/Deposit.vue"),
                meta: { title: "deposit", requiresIdentification: true },
              },
              {
                path: "deposit-list",
                name: "DepositList",
                component: () =>
                  import("@/views/dashboard/commerce/deposit/DepositList.vue"),
                meta: { title: "depositList", requiresIdentification: true },
              },
              {
                path: "packages",
                name: "Packages",
                component: () =>
                  import("@/views/dashboard/commerce/PackagesList.vue"),
                meta: { title: "packages" },
              },
            ],
          },
          {
            path: "security",
            component: () => import("@/views/dashboard/security/Security.vue"),
            children: [
              {
                path: "2fa",
                name: "2fa",
                component: () =>
                  import("@/views/dashboard/security/TwoFactorAuth.vue"),
                meta: { title: "twoFA" },
              },
              {
                path: "change-password",
                name: "ChangePasswordDashboard",
                component: () =>
                  import(
                    "@/views/dashboard/security/ChangePasswordDashboard.vue"
                  ),
                meta: { title: "changePassword" },
              },
            ],
          },
          {
            path: "reward",
            name: "Reward",
            component: () => import("@/views/dashboard/Reward.vue"),
            meta: { title: "reward" },
          },
          {
            path: "notifications",
            name: "Notifications",
            component: () => import("@/views/dashboard/NotificationsList.vue"),
            meta: { title: "notifList" },
          },
        ],
      },
      {
        path: "ticket",
        component: () => import("@/components/utils/EmptyRouterView.vue"),
        children: [
          {
            path: "list",
            name: "Tickets",
            component: () => import("@/views/dashboard/ticket/TicketsList.vue"),
            meta: { title: "ticketsList" },
          },
          {
            path: ":id",
            name: "TicketShow",
            component: () => import("@/views/dashboard/ticket/TicketItem.vue"),
            meta: { title: "ticketShow" },
          },
          {
            path: "new",
            name: "TicketCreate",
            component: () => import("@/views/dashboard/ticket/NewTicket.vue"),
            meta: { title: "newTicket" },
          },
        ],
      },
    ],
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("@/views/errors/ErrorDefault.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/errors/Error404.vue"),
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  let result = { behavior: "smooth" };

  savedPosition ? (result.savedPosition = savedPosition) : (result.top = 0);

  return result;
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

// const DEFAULT_TITLE = "ماخ‌هش";
router.beforeEach(async (to) => {
  Progress.start();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    await store.dispatch(VERIFY_AUTH);

    if (!store.getters.isAuthenticated) {
      return { name: "Login" };
    }

    if (
      !store.getters.isApprovedEmail &&
      to.matched.some((record) => record.meta.requiresEmail)
    ) {
      if (to.name !== "EmailVerification") return { name: "EmailVerification" };
    } else if (to.name === "EmailVerification") {
      return { name: "Dashboard" };
    }

    if (
      to.matched.some((record) => record.meta.requiresIdentification) &&
      +store.getters.currentUser?.["approved"] !== 1
    ) {
      return { name: "Verification" };
    }
  }

  // nextTick(() => {
  //   document.title =
  //     to.meta && to.meta.title
  //       ? to.meta.title + " - " + DEFAULT_TITLE
  //       : DEFAULT_TITLE;
  // });
  return true;
});

router.afterEach(() => {
  Progress.done();
});

// TODO: Handle proxy error or no internet; what should happen?!

export default router;
