<template>
  <div class="py-6">
    <Hero :customBgClasses="['h-96']"
          :subtitle="$t('pages.home.hero.subtitle')"
          :title="$t('pages.home.hero.title')"
          bgImage="pages/mining/cloud-mining-hero-bg.jpg">
      <div class="relative hidden lg:block">
        <div class="absolute inset-0 w-full h-96 bg-black rounded-s-10xl bg-opacity-70 overflow-hidden"></div>

        <!--        <div class="absolute start-2 top-8 h-36 w-64 bg-green-900 bg-opacity-90 border border-gray-600 rounded-xl p-4">-->
        <!--          <div class="flex flex-col h-full justify-between">-->
        <!--            <div class="flex items-center">-->
        <!--              <div class="h-1.5 w-1.5 bg-green-400 rounded-full"></div>-->

        <!--              <h6 class="ps-2 text-gray-100">{{ $t("pages.home.hero.minedBitcoin") }}</h6>-->
        <!--            </div>-->

        <!--            <div class="flex flex-col text-left">-->
        <!--              <div class="text-2xl font-extrabold">{{ info.bitcoin_mined ?? "" }}</div>-->
        <!--              <div class="text-gray-400">BTC</div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div class="absolute -start-4 top-52 h-36 w-64 bg-green-900 bg-opacity-90 border border-gray-600 rounded-xl p-4">-->
        <!--          <div class="flex flex-col h-full justify-between">-->
        <!--            <div class="flex items-center">-->
        <!--              <div class="h-1.5 w-1.5 bg-green-400 rounded-full"></div>-->

        <!--              <h6 class="ps-2 text-gray-100">{{ $t("pages.home.hero.minePower") }}</h6>-->
        <!--            </div>-->
        <!--            <div class="flex flex-col text-left">-->
        <!--              <div class="text-2xl font-extrabold">{{ info.hash_power ?? "" }}</div>-->
        <!--              <div class="text-gray-400">TH/s</div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="absolute end-10 top-20 h-56 w-80 bg-green-600 bg-gradient-to-b from-green-800 to-green-500 border border-gray-600 rounded-xl p-4">
          <div class="flex flex-col h-full justify-between">
            <div class="flex items-center">
              <div class="h-1.5 w-1.5 bg-green-400 rounded-full"></div>

              <h6 class="ps-2 text-gray-100">{{ $t("pages.home.hero.bitcoinPrice") }}</h6>
            </div>

            <!-- BTC/USD Price -->
            <div class="flex justify-end items-center px-3">
              <div class="flex flex-col text-left ltr ss01">
                <div v-if="loadingPrices">
                  <LoadingDots class="!fill-white mb-2" />
                </div>

                <div v-else
                     class="text-2xl text-white font-extrabold">
                  {{ formatNumber(BTC_USDT) }}
                </div>

                <div class="font-light text-gray-300">$ USD Dollar</div>
              </div>

              <div class="text-4xl text-gray-300 mx-4">=</div>

              <div class="flex flex-col text-left ltr ss01">
                <div class="text-2xl text-white font-extrabold">1</div>
                <div class="font-light text-gray-300">BTC</div>
              </div>
            </div>

            <!-- BTC/IRR Price -->
            <div class="flex justify-end items-center px-3">
              <div class="flex flex-col text-end">
                <div v-if="loadingPrices">
                  <LoadingDots class="!fill-white mb-2" />
                </div>
                <div v-else
                     class="text-2xl text-white font-extrabold">
                  {{ formatNumber(BTC_IRR) }}
                </div>

                <div class="font-light text-gray-300">{{ $t("toman") }}</div>
              </div>
              <div class="text-4xl text-gray-300 mx-4">=</div>
              <div class="flex flex-col text-left ltr ss01">
                <div class="text-2xl text-white font-extrabold">1</div>
                <div class="font-light text-gray-300">BTC</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Hero>

    <div class="px-6 md:px-20">
      <!-- Features -->
      <div class="grid grid-cols-1 md:grid-cols-3 mt-8">
        <Feature :text="$t('pages.home.features.titleOne')"
                 :title="$t('pages.home.features.descOne')"
                 image="makhhash-feature-1.png" />
        <Feature :text="$t('pages.home.features.titleTwo')"
                 :title="$t('pages.home.features.descTwo')"
                 image="makhhash-feature-2.png" />
        <Feature :text="$t('pages.home.features.titleThree')"
                 :title="$t('pages.home.features.descThree')"
                 image="makhhash-feature-3.png" />
      </div>

      <!-- User Comments -->
      <div class="mt-24 mb-16">
        <UserComments />
      </div>

      <!-- Packages -->
      <div class="my-24">
        <h4 class="text-3xl md:text-4xl !leading-loose font-black">
          {{ $t("pages.home.packages.title") }}
        </h4>

        <PricingPlans :custom-plan="false"
                      class="mt-8" />
      </div>

      <!-- Blog -->
      <!-- <div class="">
        <h4 class="text-3xl md:text-4xl !leading-loose font-black">
          {{ $t("social") }}
        </h4>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <router-link v-for="(post, postIdx) in posts"
                       :key="postIdx"
                       :style="{ backgroundImage: `url('${getPostImage(post, 'medium')}')` }"
                       :to="{ name: 'BlogShow', params: { id: post.id } }"
                       class="!h-72 block group bg-cover bg-center bg-no-repeat rounded-lg">
            <div class="h-full flex flex-col justify-end rounded-lg bg-gradient-to-tl from-black/80 to-transparent hover:from-black p-6">
              <h4 class="text-white text-xl font-bold text-shadow">
                {{ getPostTitle(post) }}
              </h4>
            </div>
          </router-link>
        </div>
      </div> -->
    </div>

    <NewsModal/>

  </div>
</template>

<script>
import { ref } from "vue";
import useNumberFormat from "@/core/composables/useNumberFormat";
import Hero from "@/components/main/Hero.vue";
import Feature from "@/components/main/Feature.vue";
import PricingPlans from "@/components/pricing/PricingPlans.vue";
import UserComments from "@/components/main/user-comments/index.vue";
import NewsModal from "@/components/main/features/NewsModal";
// import BlogService from "@/core/services/BlogService";
import { mapGetters } from "vuex";
import useCurrencyPrices from "@/core/composables/useCurrencyPrices";
import LoadingDots from "@/components/utils/common/LoadingDots";

export default {
  name: "Home",
  components: {
    NewsModal,
    LoadingDots,
    Hero,
    Feature,
    PricingPlans,
    UserComments,
  },
  setup() {
    const loadingPrices = ref(true);

    const { formatNumber } = useNumberFormat();

    const { getCurrencyPrices, BTC_USDT, USDT_SELL, USDT_BUY } = useCurrencyPrices();

    const BTC_IRR = ref(0);

    getCurrencyPrices()
      .then(() => {
        BTC_IRR.value = (BTC_USDT.value * ((USDT_SELL.value + USDT_BUY.value) / 2));
      }).finally(() => {
      loadingPrices.value = false;
    });

    return {
      loadingPrices,
      BTC_USDT,
      BTC_IRR,
      formatNumber,
    };
  },
  data() {
    return {
      posts: [],
    };
  },
  created() {
    // this.getPosts();
  },
  computed: {
    ...mapGetters(["currentLocale"]),
  },
  methods: {
    // getPosts() {
    //   BlogService.getLatestPosts(4, this.currentLocale)
    //     .then(({ data }) => {
    //       this.posts = data;
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
    // getPostImage(post, size = "thumbnail") {
    //   return post["_embedded"]?.["wp:featuredmedia"]?.[0]?.["media_details"]?.["sizes"]?.[size]?.["source_url"];
    // },
    // getPostImageAltText(post) {
    //   return post["_embedded"]?.["wp:featuredmedia"]?.[0]?.["alt_text"];
    // },
    // getPostTitle(post) {
    //   return post["title"]?.["rendered"];
    // },
  },
};
</script>
