<template>
  <div v-if="total > 0"
       class="flex items-center justify-between my-3 px-4 py-3 sm:px-6">
    <div class="flex flex-1 items-center justify-between">
      <nav aria-label="Pagination"
           class="relative z-0 rounded-md">
        <ul class="list-none inline-flex items-center space-s-0.5">

          <li v-if="hasEdgeNav">
            <button :class="['disabled:text-gray-500 disabled:cursor-default disabled:hover:bg-transparent']"
                    :disabled="isFirstPage"
                    class="hover:bg-green-400 hover:bg-opacity-30 rounded p-2"
                    type="button"
                    @click="setFirstPage">
              <ChevronDoubleRightIcon class="w-3 h-3 ltr:transform ltr:rotate-180" />
              <span class="sr-only">First page</span>
            </button>
          </li>

          <li>
            <button :class="['disabled:text-gray-500 disabled:cursor-default disabled:hover:bg-transparent']"
                    :disabled="isFirstPage"
                    class="hover:bg-green-400 hover:bg-opacity-30 rounded p-2"
                    type="button"
                    @click="setPrevPage">
              <ChevronRightIcon class="w-3 h-3 ltr:transform ltr:rotate-180" />
              <span class="sr-only">Previous page</span>
            </button>
          </li>

          <li v-for="page in pages"
              :key="page">
            <button :class="[
                      isCurrentPage(page.name) ? 'cursor-default text-green-900 bg-green-400' : 'hover:bg-green-400 hover:bg-opacity-30'
                    ]"
                    :disabled="isCurrentPage(page.name)"
                    class="rounded px-3 pt-1.5 pb-0.5"
                    type="button"
                    @click="setPage(page.name)">
              {{ page.name }}
            </button>
          </li>

          <li>
            <button :class="['disabled:text-gray-500 disabled:cursor-default disabled:hover:bg-transparent']"
                    :disabled="isLastPage"
                    class="hover:bg-green-400 hover:bg-opacity-30 rounded p-2"
                    type="button"
                    @click="setNextPage">
              <ChevronLeftIcon class="w-3 h-3 ltr:transform ltr:rotate-180" />
              <span class="sr-only">Next page</span>
            </button>
          </li>

          <li v-if="hasEdgeNav">
            <button :class="['disabled:text-gray-500 disabled:cursor-default disabled:hover:bg-transparent']"
                    :disabled="isLastPage"
                    class="hover:bg-green-400 hover:bg-opacity-30 rounded p-2"
                    type="button"
                    @click="setLastPage">
              <ChevronDoubleLeftIcon class="w-3 h-3 ltr:transform ltr:rotate-180" />
              <span class="sr-only">Last page</span>
            </button>
          </li>

        </ul>

      </nav>

      <p class="text-sm">
        <span>{{ t("showing") }}</span>
        <span>{{ " " + fromResult + " " }}</span>
        <span>{{ t("to") }}</span>
        <span>{{ " " + toResult + " " }}</span>
        <span>{{ t("of") }}</span>
        <span>{{ " " + total + " " }}</span>
        <span>{{ t("results") }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import { useI18n } from "vue-i18n";

export default {
  name: "CustomPagination",
  components: {
    ChevronDoubleLeftIcon,
    ChevronLeftIcon,
    ChevronDoubleRightIcon,
    ChevronRightIcon,
  },
  props: {
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    maxVisiblePages: {
      type: Number,
      required: false,
      default: 5,
    },
    hasEdgeNav: {
      type: Boolean,
      default: true,
    },
    hasChunksNav: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { t } = useI18n({
      messages: {
        fa: {
          showing: "نمایش",
          to: "تا",
          of: "از",
          results: "نتیجه",
        },
        en: {
          showing: "showing",
          to: "to",
          of: "of",
          results: "results",
        },
      },
    });

    return {
      t,
    };
  },
  computed: {
    pages() {
      const range = [];

      let startPage = (this.currentPage - Math.floor(this.maxVisiblePages / 2));
      let endPage = (this.currentPage + Math.floor(this.maxVisiblePages / 2));

      if (startPage < 1) {
        startPage = 1;
        endPage = this.maxVisiblePages;
      }

      if (endPage > this.totalPages) {
        startPage = this.totalPages - (this.maxVisiblePages - 1);
        endPage = this.totalPages;

        if (startPage < 1)
          startPage = 1;
      }

      for (let page = startPage; page <= endPage; page++) {
        range.push({ name: page, isDisabled: page === this.currentPage });
      }

      return range;
    },
    fromResult() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    toResult() {
      return this.currentPage * this.perPage > this.total ? this.total : this.currentPage * this.perPage;
    },
    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },
    isFirstPage() {
      return this.currentPage === 1;
    },
    isLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    isCurrentPage(page) {
      return this.currentPage === page;
    },
    setFirstPage() {
      this.$emit("paginate", 1);
    },
    setPrevPage() {
      this.$emit("paginate", this.currentPage - 1);
    },
    setPage(page) {
      this.$emit("paginate", page);
    },
    setNextPage() {
      this.$emit("paginate", this.currentPage + 1);
    },
    setLastPage() {
      this.$emit("paginate", this.totalPages);
    },
  },
};
</script>

<style scoped>

</style>
