<template>
  <TransitionRoot :show="open"
                  as="template">
    <Dialog as="div"
            class="fixed z-50 inset-0 overflow-y-auto flex items-center justify-center"
            @close="closeModal">
      <div class="flex items-end justify-center text-center sm:block pt-4 px-4 pb-20 sm:p-0">
        <TransitionChild as="template"
                         enter="ease-out duration-300"
                         enter-from="opacity-0"
                         enter-to="opacity-100"
                         leave="ease-in duration-200"
                         leave-from="opacity-100"
                         leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <span aria-hidden="true"
              class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
        <TransitionChild as="template"
                         enter="ease-out duration-300"
                         enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                         enter-to="opacity-100 translate-y-0 sm:scale-100"
                         leave="ease-in duration-200"
                         leave-from="opacity-100 translate-y-0 sm:scale-100"
                         leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div :class="['sm:my-8 px-4 py-4 sm:p-6']"
               class="inline-block align-bottom bg-white dark:bg-mhGray-800 text-mhGray-900 dark:text-white text-start
                      rounded-lg overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-sm sm:w-full">
            <div v-if="closeable"
                 class="absolute top-0 end-0 pt-4 pe-4">
              <button class="rounded-sm focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-gray-300 dark:focus:ring-gray-800"
                      type="button"
                      @click="$emit('closed')">
                <span class="sr-only">Close</span>
                <XIcon aria-hidden="true"
                       class="h-5 w-5" />
              </button>
            </div>

            <div class="space-y-6">
              <div>
                <DialogTitle>
                  <slot name="title" />
                </DialogTitle>
              </div>

              <div>
                <slot />
              </div>

              <div>
                <slot name="footer" />
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closed"],
  methods: {
    closeModal() {
      if (!this.closeable)
        return;

      this.$emit("closed");
    },
  },
};
</script>
