import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
import "./main.css";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { VueReCaptcha } from "vue-recaptcha-v3";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import "nprogress/nprogress.css";

import ApiService from "@/core/services/ApiService";

import { initVeeValidate } from "@/core/plugins/vee-validate";
import i18n from "@/core/plugins/i18n";

import BaseLink from "@/components/utils/links/BaseLink.vue";
import BaseInput from "@/components/utils/form/BaseInput.vue";
import BaseInputFile from "@/components/utils/form/BaseInputFile";
import BaseTextarea from "@/components/utils/form/BaseTextarea.vue";
import BaseSelect from "@/components/utils/form/BaseSelect.vue";
import BaseButton from "@/components/utils/buttons/BaseButton.vue";
import BaseHeading from "@/components/utils/headings/BaseHeading.vue";
import BaseTable from "@/components/utils/tables/BaseTable.vue";
import BaseAlert from "@/components/utils/alerts/BaseAlert.vue";
import BaseTabs from "@/components/utils/tabs/BaseTabs.vue";
import BaseBadge from "@/components/utils/badges/BaseBadge";
import TableComponent from "@/components/utils/tables/TableComponent";
import TableColumn from "@/components/utils/tables/TableColumn";
import TableRow from "@/components/utils/tables/TableRow";
import TableCell from "@/components/utils/tables/TableCell";
import CustomPagination from "@/components/utils/paginations/CustomPagination";
import Spinner from "@/components/utils/common/Spinner";

// import vue3PersianDatetimePicker
import Vue3PersianDatetimePicker from "vue3-persian-datetime-picker";

import "./registerServiceWorker";
import "@/core/plugins/goftino";

ApiService.init();

const Vue = createApp(App);

Vue.use(router);
Vue.use(store);

initVeeValidate();

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });
Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 10,
  newestOnTop: true,
  position: "top-left",
  timeout: 5000,
  rtl: true,
  closeOnClick: false,
});
Vue.use(i18n);

Vue.component("BaseLink", BaseLink);
Vue.component("BaseInput", BaseInput);
Vue.component("BaseInputFile", BaseInputFile);
Vue.component("BaseTextarea", BaseTextarea);
Vue.component("BaseSelect", BaseSelect);
Vue.component("BaseButton", BaseButton);
Vue.component("BaseHeading", BaseHeading);
Vue.component("BaseTable", BaseTable);
Vue.component("BaseAlert", BaseAlert);
Vue.component("BaseTabs", BaseTabs);
Vue.component("BaseBadge", BaseBadge);
Vue.component("TableComponent", TableComponent);
Vue.component("TableColumn", TableColumn);
Vue.component("TableRow", TableRow);
Vue.component("TableCell", TableCell);
Vue.component("CustomPagination", CustomPagination);
Vue.component("Spinner", Spinner);

Vue.component("DatePicker", Vue3PersianDatetimePicker);

Vue.mount("#app");

export default Vue;
