<template>
  <div :class="[
    'loader',
    'inline-block align-middle',
    'animate-spin ease-linear',
    'rounded-full border-4 border-t-4',
    'border-gray-400 dark:border-gray-600',
    'h-8 w-8']"></div>
</template>

<script>
export default {
  name: "Spinner",
};
</script>

<style scoped>
.dark .loader, .dark.loader {
  border-top-color: transparent; /*rgba(229, 231, 235, 1)*/
}

.loader, .light.loader {
  border-top-color: transparent; /*rgba(75, 85, 99, 1)*/
}
</style>
