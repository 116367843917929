<template>
  <div>
    <label v-if="label"
           :for="id"
           class="block text-sm dark:text-gray-300 mb-2">{{ label }}</label>

    <textarea :id="id"
              :class="[
                'border',
                error.length
                  ? 'border-red-500'
                  : 'border-gray-300 dark:border-gray-700 focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-700',
              ]"
              :value="modelValue"
              class="w-full bg-white dark:bg-mhGray-600 outline-none text-sm dark:text-gray-300 rounded-lg shadow-sm p-4"
              v-bind="$attrs"
              @input="updateValue" />

    <FieldErrorMessage v-if="error.length"
                       class="mt-2">
      {{ error }}
    </FieldErrorMessage>
  </div>
</template>

<script>
import FieldErrorMessage from "@/components/utils/form/FieldErrorMessage";

export default {
  name: "BaseTextarea",
  inheritAttrs: false,
  components: {
    FieldErrorMessage,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: [String, Number],
    id: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>
