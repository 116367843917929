<template>
  <td class="whitespace-nowrap font-light
             border-t border-b border-gray-300 dark:border-gray-700 first:border-s first:rounded-s-xl last:border-e last:rounded-e-xl
             px-4 py-4 md:py-6">
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: "TableCell",
};
</script>
