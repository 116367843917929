<template>
  <div v-if="updateExists"
       class="fixed w-4/5 md:w-1/2 lg:w-1/3 start-5 bottom-5 rounded bg-gray-700 text-gray-100 text-sm shadow-xl space-y-2 p-4"
       style="z-index: 9999999999;">
    <p>{{ t("alertText") }}</p>

    <div class="flex justify-end space-s-1">
      <base-button class="btn-sm shadow"
                   variant="success"
                   @click="refreshApp">
        {{ t("reload") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import SWUpdateMixin from "@/core/mixins/SWUpdateMixin";

export default {
  name: "SWUpdatePrompt",
  data() {
    return {
      show: true,
    };
  },
  setup() {
    const { t } = useI18n({
      messages: {
        fa: {
          alertText: "محتوای جدیدی در دسترس است. جهت بروزرسانی دکمه بارگذاری را بزنید.",
          reload: "بارگذاری",
        },
        en: {
          alertText: "New content available, click on reload button to update",
          reload: "Reload",
        },
      },
    });

    return {
      t,
    };
  },
  mixins: [SWUpdateMixin],
};
</script>
