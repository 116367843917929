<template>
  <div :class="[widthClass]"
       class="hidden md:block h-0.5 ltr:bg-gradient-to-l rtl:bg-gradient-to-r from-transparent via-transparent to-green-400 rounded-full"></div>
</template>

<script>
export default {
  name: "OneSideFadedDivider",
  props: {
    widthClass: {
      type: String,
      default: "w-1/3",
    },
  },
};
</script>