<template>
  <div class="grid grid-cols-1 md:grid-cols-2 items-center">
    <div class="relative mx-4 md:pb-6 md:ms-12 mb-8 md:mb-0">
      <h2 class="text-3xl md:text-5xl font-black leading-relaxed md:leading-relaxed mb-4">
        {{ title }}
      </h2>
      <h6 v-show="subtitle.length"
          class="md:text-lg text-gray-900 dark:text-gray-400">
        {{ subtitle }}
      </h6>
      <div v-show="hasDivider"
           class="hidden md:block absolute bottom-0 right-0 left-0 w-2/3 h-1 ltr:bg-gradient-to-l rtl:bg-gradient-to-r from-transparent via-transparent to-green-400 rounded-full"></div>
    </div>

    <div :class="[customBgClasses]"
         :style="bgImageStyle"
         class="h-80 md:rounded-s-10xl bg-green-500 bg-no-repeat bg-center bg-cover bg-gradient-to-b from-green-800 to-green-400">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    hasDivider: {
      type: Boolean,
      default: true,
    },
    bgImage: {
      type: String,
      default: "",
    },
    customBgClasses: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    bgImageStyle() {
      if (!this.bgImage) return {};
      return {
        backgroundImage: `url(${require("@/assets/images/" + this.bgImage)})`,
      };
    },
  },
};
</script>
