<template>
  <div>
    <label v-if="label" :for="id" class="block text-sm dark:text-gray-300 mb-2">
      {{ label }}
    </label>

    <div :class="[
      'text-mhGray-900 dark:text-gray-300',
      'bg-white dark:bg-mhGray-600',
      'border border-gray-300 dark:border-gray-700 rounded-lg',
      { 'shadow-lg': focused },
      { 'border-transparent ring-2 ring-gray-400 dark:ring-gray-700': focused && !error.length },
      { '!border-red-400': error.length },
    ]" class="flex items-center w-full text-sm shadow-sm">
      <span v-if="hasSlot('prepend')"
        class="text-center text-green-500 text-sm font-bold border-e border-gray-300 dark:border-gray-600 px-6 py-1 my-3">
        <slot name="prepend" />
      </span>

      <input :id="id" :value="modelValue"
        class="w-full bg-transparent outline-none disabled:text-gray-600 dark:disabled:text-gray-500 p-4"
        v-bind="$attrs" @blur="focused = false" @focus="focused = true" @input="updateValue" />

      <span v-if="hasSlot('append')"
        class="text-center text-green-500 text-sm font-bold border-s border-gray-300 dark:border-gray-600 px-6 py-1 my-3">
        <slot name="append" />
      </span>
    </div>

    <FieldErrorMessage v-if="error.length" class="mt-2">
      {{ error }}
    </FieldErrorMessage>

    <div v-if="hasSlot('hint')" class="text-gray-600 dark:text-gray-400 text-xs mt-1">
      <slot name="hint" />
    </div>
  </div>
</template>

<script>
import FieldErrorMessage from "@/components/utils/form/FieldErrorMessage";
import { convertNumbersToEnglish } from "@/core/helpers/number";

export default {
  inheritAttrs: false,
  components: {
    FieldErrorMessage,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: [String, Number],
    id: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    updateValue(event) {
      const convertedValue = convertNumbersToEnglish(event.target.value);
      this.$emit("update:modelValue", convertedValue);
    },
    hasSlot(name) {
      return this.$slots[name] !== undefined;
    },
  },
};
</script>
