<template>
  <tr class="bg-white dark:bg-mhGray-800 hover:bg-opacity-50 transition-colors">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: "TableRow",
};
</script>
