import { useToast } from "vue-toastification";

const toast = useToast();

const notifier = {
  notify(message, options = {}) {
    toast(message, options);
  },
  success(message, options = {}) {
    toast.success(message, options);
  },
  info(message, options = {}) {
    toast.info(message, options);
  },
  warning(message, options = {}) {
    toast.warning(message, options);
  },
  error(message, options = {}) {
    toast.error(message, options);
  }
};

export default notifier;