<template>
  <div>
    <BaseLabel v-if="label"
               :id="id">
      {{ label }}
    </BaseLabel>

    <template v-if="!search">
      <Listbox v-model="selected">
        <div class="relative">
          <ListboxButton
            :id="id"
            :class="[
              'relative w-full text-start text-sm bg-white dark:bg-mhGray-600 text-mhGray-900 dark:text-gray-300 cursor-default',
              'border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm',
              'focus:outline-none focus:ring-2 focus:ring-gray-300 focus-visible:ring-2 focus-visible:ring-gray-300',
              'py-4 ps-4 pe-10',
            ]"
          >
            <span class="block truncate">{{ selected.text }}</span>

            <span
              class="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none"
            >
              <SelectorIcon aria-hidden="true"
                            class="w-5 h-5 text-gray-400" />
            </span>
          </ListboxButton>

          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              :class="[
                'absolute w-full py-1 mt-1 overflow-auto text-base shadow-lg max-h-60 sm:text-sm z-10',
                'bg-gray-200 dark:bg-mhGray-600 rounded',
                'ring-1 ring-black ring-opacity-5 focus:outline-none'
              ]"
            >
              <ListboxOption
                v-for="(item, itemIdx) in items"
                :key="itemIdx"
                v-slot="{ active, selected }"
                :value="item"
                as="template"
              >
                <li
                  :class="[
                    { 'bg-gray-300 dark:bg-mhGray-700': active },
                    'cursor-default select-none relative transition-colors py-2 ps-10 pe-4',
                  ]"
                >
                  <span
                    :class="[
                      selected ? 'font-medium' : 'font-normal',
                      'block truncate',
                    ]"
                  >
                    {{ item.text }}
                  </span>
                  <span
                    v-if="selected"
                    class="absolute inset-y-0 start-0 flex items-center ps-3 text-green-500"
                  >
                    <CheckIcon aria-hidden="true"
                               class="w-5 h-5" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </template>

    <template v-else>
      <Combobox v-model="selected">
        <div class="relative">
          <div
            :class="[
              'relative w-full bg-white dark:bg-mhGray-600 text-mhGray-900 dark:text-gray-300 rounded cursor-default overflow-hidden',
              'focus:outline-none focus:ring-2 focus:ring-gray-300 focus-visible:ring-2 focus-visible:ring-gray-300'
            ]"
          >
            <ComboboxInput
              :id="id"
              :class="[
                'relative w-full text-start text-sm bg-white dark:bg-mhGray-600 text-mhGray-900 dark:text-gray-300 cursor-default',
                'border border-gray-300 dark:border-gray-700 rounded-lg shadow-md',
                'focus:outline-none focus:ring-2 focus:ring-gray-300 focus-visible:ring-2 focus-visible:ring-gray-300',
                'py-4 ps-4 pe-10',
              ]"
              autocomplete="off"
              :displayValue="(item) => item.text"
              @change="query = $event.target.value"
            />

            <ComboboxButton
              class="absolute inset-y-0 end-0 flex items-center pe-2"
            >
              <SelectorIcon aria-hidden="true"
                            class="w-5 h-5 text-gray-400" />
            </ComboboxButton>
          </div>

          <TransitionRoot
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            @after-leave="query = ''"
          >
            <ComboboxOptions
              class="absolute w-full overflow-auto text-base bg-gray-200 dark:bg-mhGray-600 rounded shadow-lg py-1 mt-1
                     max-h-60 focus:outline-none ring-1 ring-black ring-opacity-5 sm:text-sm"
            >
              <div
                v-if="filteredResult.length === 0 && query !== ''"
                class="cursor-default select-none relative py-2 px-4"
              >
                Nothing found.
              </div>

              <ComboboxOption
                v-for="item in filteredResult"
                :key="item.id"
                v-slot="{ selected, active }"
                :value="item"
                as="template"
              >
                <li
                  :class="{
                    'bg-gray-300 dark:bg-mhGray-700': active,
                  }"
                  class="select-none relative py-2 ps-10 pe-4"
                >
                  <span
                    :class="{
                      'font-medium': selected,
                      'font-normal': !selected,
                    }"
                    class="block truncate"
                  >
                    {{ item.text }}
                  </span>

                  <span
                    v-if="selected"
                    class="absolute text-green-500 inset-y-0 start-0 flex items-center ps-3"
                  >
                    <CheckIcon aria-hidden="true"
                               class="w-5 h-5" />
                  </span>
                </li>
              </ComboboxOption>
            </ComboboxOptions>
          </TransitionRoot>
        </div>
      </Combobox>
    </template>
  </div>
</template>

<script>
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import BaseLabel from "@/components/utils/form/BaseLabel";

export default {
  name: "BaseSelect",
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    Combobox,
    ComboboxInput,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot,
    CheckIcon,
    SelectorIcon,
    BaseLabel,
  },
  props: {
    label: String,
    search: {
      type: Boolean,
      default: false,
    },
    id: String,
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ["update"],
  data() {
    return {
      selected: { value: "", text: "" },
      query: "",
    };
  },
  mounted() {
    if (this.items?.length)
      this.selected = this.items[0];
  },
  watch: {
    selected(newValue) {
      this.$emit("update", newValue);
    },
    items() {
      if (this.items?.length)
        this.selected = this.items[0];
    },
  },
  computed: {
    filteredResult() {
      if (this.query === "")
        return this.items;

      return this.items.filter((item) =>
        item.text
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(this.query.toLowerCase().replace(/\s+/g, "")),
      );
    },
  },
};
</script>
