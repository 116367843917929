<template>
  <div class="flex justify-center items-center">
    <StarIcon
      v-for="rate in [0, 1, 2, 3, 4]"
      :key="rate"
      :class="[
              rating > rate ? 'text-yellow-500 dark:text-yellow-300' : 'text-gray-400 dark:text-gray-600',
              'h-6 w-6 flex-shrink-0'
            ]"
      aria-hidden="true"
    />
    <p class="sr-only">{{ rating }} out of 5 stars</p>
  </div>
</template>

<script>
import { StarIcon } from "@heroicons/vue/solid";

export default {
  name: "RatingStars",
  components: {
    StarIcon,
  },
  props: {
    rating: {
      type: Number,
      required: true,
    },
  },
};
</script>
